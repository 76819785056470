import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { ZenduOne } from "src/typings/app";

@Injectable()
export class VehicleService {
    constructor(private _httpService: HttpService) { }

    public async findOne(id: string): Promise<ZenduOne.Vehicle> {
        let items = await this._httpService.post("vehicles/find", { id: id });
        return items[0];
    }

    public async find(search: { customerId?: string }): Promise<Array<ZenduOne.Vehicle>> {
        let items = await this._httpService.post("vehicles/find", search);
        return items;
    }

    public async update(vehicle: ZenduOne.Vehicle) {
        await this._httpService.post("vehicles/update", vehicle);
    }

    public async add(vehicle: ZenduOne.Vehicle): Promise<{ id: string }> {
        let res = await this._httpService.post("vehicles/add", vehicle);
        return res;
    }

    public async remove(id: string) {
        await this._httpService.post("vehicles/remove", { id: id });
    }

}