import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'src/app/services/notifier.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { CustomerService } from 'src/app/services/customer.service';
import { CancelConfirmComponent } from 'src/app/dialogs/cancel-confirm/cancel-confirm.component';
import { MatDialog } from '@angular/material';
import { AuthService } from 'src/app/services/auth.service';
import { ZenduOne } from 'src/typings/app';
import { BillingService } from 'src/app/services/billing.service';

@Component({
  selector: 'app-zoho-profile',
  templateUrl: './zoho-profile.component.html',
  styleUrls: ['./zoho-profile.component.scss']
})
export class ZohoProfileComponent implements OnInit {
  public isLoading: boolean;

  constructor(
    private _notify: NotifierService,
    private _activedRoute: ActivatedRoute,
    private _customerService: CustomerService,
    private _auth: AuthService,
    private _dialog: MatDialog,
    private _router: Router,
    private _billingService: BillingService) { }

  ngOnInit() {
    this.init();
  }

  private async init() {
    try {
      this.isLoading = true;

      let authRes = await this._auth.initAuth();

      if (!authRes) {
        // user is not authirized
        // redirect user to login page
        // add the backward url for successfull athorization
        this._router.navigate(['sign-in'], {
          queryParams: {
            backurl: document.location.href
          }
        });
        return;
      }

      let query = this._activedRoute.snapshot.queryParams;

      let customer = await this.findUserByQuery(query);

      if (!customer) {
        // run workflow for a new customer
        await this.newCustomerWorkflow();
        return;
      }

      // run workflow for a existing customer
      await this.existCustomerWorkflow(customer);
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  private async findUserByQuery(query) {
    const zohoId = query.zohoId;
    if (!zohoId) {
      throw "zoho id is not passed";
    }
    let customers = await this._customerService.find({ zohoAccountId: query.zohoId });
    let customer = customers[0];
    if (!customer) {
      // try to find the user by database name
      const database = query.database;
      if (database) {
        customers = await this._customerService.find({ platform: { database: database } });
        customer = customers[0];
      }
    }
    if (!customer) {
      // try to find the user by email
      const email = query.email;
      if (email) {
        customers = await this._customerService.find({ email: email });
        customer = customers[0];
      }
    }
    return customer;
  }

  /**
   * workflow for a existing customer
   */
  private async existCustomerWorkflow(customer: ZenduOne.Customer) {
    let query = this._activedRoute.snapshot.queryParams;

    // check geotab myadmin id
    //

    let needUpdate = false;
    const myAdminId = query.myAdminId;
    if (myAdminId && !customer.geotabMyAdminId) {
      customer.geotabMyAdminId = myAdminId;
      needUpdate = true;
    }
    else if (myAdminId && customer.geotabMyAdminId &&
      customer.geotabMyAdminId != myAdminId) {
      // ask user if he want update my admin id
      const dialogRef = this._dialog.open(CancelConfirmComponent, {
        width: '350px',
        data: {
          title: "My Admin ID",
          text: `Zenduone MyAdmin ID is not equal to Zoho Geotab Contact ID. Update Zenduone MyAdmin ID ?`
        }
      });
      let confirm = await dialogRef.afterClosed().toPromise();
      if (confirm) {
        customer.geotabMyAdminId = myAdminId;
        needUpdate = true;
      }
    }

    // check zoho account id
    //
    const zohoId = query.zohoId;
    if (zohoId && !customer.zohoAccountId) {
      // link to given zoho id
      customer.zohoAccountId = zohoId;
      needUpdate = true;
    }
    else if (zohoId && customer.zohoAccountId &&
      customer.zohoAccountId != zohoId) {
      // ask user if he want update zoho id
      const dialogRef = this._dialog.open(CancelConfirmComponent, {
        width: '350px',
        data: {
          title: "Zoho Account ID",
          text: `Zenduone Zoho Account ID is not equal to passed Zoho Account ID. Update Zenduone Zoho Account ID  ?`
        }
      });
      let confirm = await dialogRef.afterClosed().toPromise();
      if (confirm) {
        customer.zohoAccountId = zohoId;
        needUpdate = true;
      }
    }

    if (needUpdate) {
      // update account
      await this._customerService.update({
        customer: customer,
        options: {
          assignZohoAccount: false,
          assignMyAdminAccount: false,
          creditCardToken: null
        }
      });
    }

    // navigate the user to customer's editing page
    this._router.navigate(['/management/customer/edit'], {
      queryParams: {
        id: customer._id,
        initMyAdmin: myAdminId ? true : false
      }
    });
  }

  /**
   * workflow for a new user creation based on zoho account
   */
  private async newCustomerWorkflow() {
    const dialogRef = this._dialog.open(CancelConfirmComponent, {
      width: '350px',
      data: {
        title: "New Customer",
        text: `Customer is not exist in the ZenduOne platform. Create a new customer ?`
      }
    });
    let confirm = await dialogRef.afterClosed().toPromise();
    if (!confirm) {
      return;
    }

    // create a new user
    let query = this._activedRoute.snapshot.queryParams;
    const myAdminId = query.myAdminId;
    const zohoId = query.zohoId;
    const email = query.email as string;
    const customerName = email.match(/[a-zA-Z0-9]+/g).join("-");
    const customer = {
      name: customerName,
      email: email,
      zohoAccountId: zohoId,
      geotabMyAdminId: myAdminId,
      billingAddress: this.getAddres(query),
      shippingAddress: this.getAddres(query)
    } as ZenduOne.Customer;

    let data = await this._customerService.add({
      customer: customer,
      assignZohoAccount: false,
      creditCardToken: null
    });

    // navigate the user to customer's editing page
    this._router.navigate(['/management/customer/edit'], {
      queryParams: {
        id: data.id,
        initMyAdmin: myAdminId ? true : false
      }
    });
  }

  private getAddres(query: Params) {
    return {
      street: query.street || "-",
      city: query.city || "-",
      state: query.state || "-",
      country: query.country || "-",
      code: query.postal || "-"
    } as ZenduOne.Address;
  }
}
