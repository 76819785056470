import { Component, OnInit } from '@angular/core';
import { ZenduOne } from 'src/typings/app';
import { NotifierService } from 'src/app/services/notifier.service';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { RoleService } from 'src/app/services/role.service';
import { ResellerService } from 'src/app/services/reseller.service';
import { UserRoleName } from 'src/typings/userRoleName';
import { CustomerService } from 'src/app/services/customer.service';
import { MatDialog } from '@angular/material';
import { CancelConfirmComponent } from 'src/app/dialogs/cancel-confirm/cancel-confirm.component';

@Component({
    selector: 'app-users-edit',
    templateUrl: './users-edit.component.html',
    styleUrls: ['./users-edit.component.scss']
})
export class UsersEditComponent implements OnInit {

    public isLoading: boolean;

    public user: ZenduOne.User;

    public isNewUser: boolean;

    public roles: Array<ZenduOne.Role>;

    public resellers: Array<ZenduOne.Reseller>;

    public selectedRole: string;

    public selectedCustomer: ZenduOne.Customer;

    constructor(
        private _notify: NotifierService,
        private _userService: UserService,
        private _roleService: RoleService,
        private _resellerService: ResellerService,
        private _activeRoute: ActivatedRoute,
        private _customerService: CustomerService,
        private _router: Router,
        private _dialog: MatDialog) {

    }

    ngOnInit() {
        this.init();
    }

    private async init() {
        try {
            this.isLoading = true;

            // load roles
            this.roles = await this._roleService.find();
            // load resellers
            this.resellers = await this._resellerService.find();

            let query = this._activeRoute.snapshot.queryParams;
            if (query.id) {
                const id = query.id;
                // load existing user
                await this.loadUser(id);
            }
            else {
                // new user
                this.isNewUser = true;
                this.user = {
                    name: "",
                    roles: []
                }

                this.selectedRole = this.roles[0].name;
            }
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    private async loadUser(id: any) {
        this.user = await this._userService.findOne(id);
        this.selectedRole = this.user.roles[0];

        if (this.user.customerId) {
            this.selectedCustomer = await this._customerService.findOne(this.user.customerId);
        }
    }

    public async resetPasword() {
        try {
            this.isLoading = true;

            await this._userService.resetPassword(this.user._id);

            this._notify.success("Password is restored. Please checkout your email box.")
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    public async delete() {
        const dialogRef = this._dialog.open(CancelConfirmComponent, {
            width: '350px',
            data: {
                title: "Confirm",
                text: `Are you sure you want to remove this user?`
            }
        });
        let confirm = await dialogRef.afterClosed().toPromise();
        if (!confirm) {
            return;
        }

        await this._userService.remove(this.user._id);

        this._notify.success("User removed")
        this._router.navigate(["/management/user/list"])
    }

    public async save() {
        try {
            this.isLoading = true;

            // assign selected role
            this.user.roles = [this.selectedRole];

            // trim user email to avoid sync issues
            this.user.name = this.user.name.trim();

            // assign selected customer
            if (this.selectedCustomer) {
                this.user.customerId = this.selectedCustomer._id;
            }

            if (this.isNewUser) {
                const id = await this._userService.add(this.user);
                this._notify.success("User added");
                // fetch fresh data
                await this.loadUser(id);
            }
            else {
                await this._userService.update(this.user);
                this._notify.success("User saved.");
            }

        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    public isResellerVisible() {
        if (!this.selectedRole) {
            return false;
        }
        return (this.selectedRole == UserRoleName.reseller ||
            this.selectedRole == UserRoleName.sales ||
            this.selectedRole == UserRoleName.partnerApi);
    }

    public isCustomerVisible() {
        if (!this.selectedRole) {
            return false;
        }
        return (this.selectedRole == UserRoleName.customer ||
            this.selectedRole == UserRoleName.service);
    }
}
