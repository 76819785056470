import { Component, OnInit, Inject, Optional } from '@angular/core';
import { NotifierService } from 'src/app/services/notifier.service';
import { ZenduOne } from 'src/typings/app';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { FileHolder } from 'angular2-image-upload';

@Component({
    selector: 'app-preview-edit',
    templateUrl: './preview-edit.component.html',
    styleUrls: ['./preview-edit.component.scss']
})
export class PreviewEditComponent implements OnInit {

    public isLoading: boolean;

    public item: ZenduOne.ProductPreview;

    public uploadUrl: string;

    public headers: {};

    constructor(
        @Optional() private _taskDialog: MatDialogRef<PreviewEditComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ZenduOne.ProductPreview,
        private _notify: NotifierService,
        private _authService: AuthService
    ) {

        this.uploadUrl = `${environment.service}/cloudstrorage/upload`
        this.headers = {
            'Authorization': this._authService.getToken()
        };

        this.item = data ? data : {
            icon: "",
            name: "",
            preview: ""
        };
    }

    ngOnInit() {
    }

    public save() {
        try {
            if (!this.item.name) {
                throw `Name is empty`
            }
            if (!this.item.preview) {
                throw `Preview Url is empty`
            }

            this._taskDialog.close(this.item);
        }
        catch (err) {
            this._notify.error(err);
        }
    }


    public onUploadIcon(data: FileHolder) {
        let url = this.getUploadedUrl(data);
        if (url) {
            this.item.icon = url;
        }
        else {
            this._notify.error("Upload failed");
        }
    }

    public onUploadScreenshot(data: FileHolder) {
        let url = this.getUploadedUrl(data);
        if (url) {
            this.item.preview = url;
        }
        else {
            this._notify.error("Upload failed");
        }
    }

    private getUploadedUrl(data: FileHolder) {
        if (data &&
            data.serverResponse &&
            data.serverResponse.response &&
            data.serverResponse.response.body) {
            let resp = JSON.parse(data.serverResponse.response.body);
            if (resp.url) {
                return resp.url;
            }
        }
        return "";
    }
}
