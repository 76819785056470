import { Component, OnInit, ViewChild } from '@angular/core';
import { CustomerService } from 'src/app/services/customer.service';
import { ZenduOne } from 'src/typings/app';
import { NotifierService } from 'src/app/services/notifier.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteBillingCustomer } from 'src/typings/billing';
import { BillingService } from 'src/app/services/billing.service';
import { ResellerService } from 'src/app/services/reseller.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { CountryService } from 'src/app/services/country.service';

@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.scss']
})
export class CustomersEditComponent implements OnInit {

  @ViewChild("card-element") cardElement;

  public isNew: boolean;

  public isCreditCardAvail: boolean;

  public customer: ZenduOne.Customer;

  public billing: RouteBillingCustomer;

  public isLoading: boolean;

  public shippingSameAsShipping: boolean;

  public assignZohoAccount: boolean;

  public assignMyAdminAccount: boolean;

  public creditCardToken: string;

  public countries: Array<{ id: string, name: string }>

  public resellers: Array<ZenduOne.Reseller>;

  public months: Array<number>;

  public years: Array<number>;

  public _cardIsInitalized: boolean;

  public isAdmin: boolean;

  public initMyAdmin: boolean;

  private _stripe: Stripe;

  private _card: StripeElement;

  constructor(
    private _notify: NotifierService,
    private _customerService: CustomerService,
    private _activedRoute: ActivatedRoute,
    private _billingService: BillingService,
    private _resellerService: ResellerService,
    private _authService: AuthService,
    private _router: Router,
    private _countryService: CountryService
  ) {
    this.shippingSameAsShipping = true;
  }

  ngOnInit() {
    this.init();
  }

  private async init() {
    try {
      this.isLoading = true;

      this.isAdmin = this._authService.isAdmin();

      this.countries = this._countryService.getList();

      this.months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

      this.years = [];
      for (let idx = 0; idx <= 30; idx++) {
        this.years.push(new Date().getFullYear() + idx);
      }

      this.resellers = await this._resellerService.find();

      await this.load();
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  private async load() {
    try {
      this.isLoading = true;

      let query = this._activedRoute.snapshot.queryParams;
      if (query.id) {
        this.customer = await this._customerService.findOne(query.id);

        this.initMyAdmin = query.initMyAdmin == "true";

        if (!this.customer.billingAddress) {
          // setup default billing address
          this.customer.billingAddress = {};
        }

        if (!this.customer.shippingAddress) {
          // setup default shipping address
          this.customer.shippingAddress = {};
        }

        this.billing = await this._billingService.findCustomerBilling({ customerId: this.customer._id });

      }
      else {
        let resellerId = this._authService.getResellerCode()

        this.isNew = true;
        this.customer = {
          name: "New Customer",
          email: "",
          resellerId: resellerId,// set default reseller for reseller's users
          paymentMode: "send_invoice",
          geotabMyAdminId: "",
          externalBilling: false,
          billingAddress: {
            country: "USA"
          },
          shippingAddress: {
            country: "USA"
          },
          billingEmail: "",
          phone: "",
          platforms: []
        };
      }

    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  public initalizeCardPayment() {
    if (this._cardIsInitalized) {
      return;
    }
    // Create a Stripe client.
    this._stripe = new Stripe(environment.stripeKey);

    // Create an instance of Elements.
    let elements = this._stripe.elements();

    // Custom styling can be passed to options when creating an Element.
    // (Note that this demo uses a wider set of styles than the guide below.)
    let style = {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    // Create an instance of the card Element.
    this._card = elements.create('card', { style: style });

    // Add an instance of the card Element into the `card-element` <div>.
    this._card.mount('#card-element');

    this._cardIsInitalized = true;
  }

  private async submitPaymentCard() {
    if (!this._stripe) {
      throw "stripe is not configured"
    }
    let result = await this._stripe.createToken(this._card);

    if (result.error) {
      throw result.error.message;
    }

    if (!result.token) {
      throw "card token is empty"
    }

    return result.token.id;
  }

  public async save() {
    try {

      this.validate();

      this.isLoading = true;

      // trim emails to avoid sync issues
      this.customer.email = this.customer.email.trim();
      this.customer.billingEmail = this.customer.billingEmail.trim();

      if (this.isCreditCardAvail) {
        if (!this.creditCardToken) {
        }
      }

      if (this.isNew) {
        //
        // add a new customer
        //
        let data = await this._customerService.add({
          customer: this.customer,
          assignZohoAccount: this.assignZohoAccount,
          creditCardToken: this.creditCardToken
        });
        this.customer._id = data.id;
        this._router.navigate(['/management/customer/edit'], { queryParams: { id: data.id } });

        this._notify.success("Customer created")
      }
      else {
        //
        // update exist customer
        //
        await this._customerService.update({
          customer: this.customer,
          options: {
            assignZohoAccount: this.assignZohoAccount,
            assignMyAdminAccount: this.assignMyAdminAccount,
            creditCardToken: this.creditCardToken
          }
        });

        if (this.assignZohoAccount || this.assignMyAdminAccount) {
          // reload customer information
          this.customer = await this._customerService.findOne(this.customer._id);
          this.assignZohoAccount = false;
        }

        this._notify.success("Customer updated")
      }
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }


  private validate() {
    this.validateAddress(this.customer.shippingAddress);

    if (this.shippingSameAsShipping) {
      // clone billing address to shipping
      this.customer.billingAddress = Object.assign({}, this.customer.shippingAddress);
    }
    else {
      // validate billing information
      if (!this.customer.billingEmail.trim()) {
        throw "billing email is empty"
      }
      this.validateAddress(this.customer.billingAddress);
    }
  }


  private validateAddress(addr: ZenduOne.Address) {
    if (!addr.street || !addr.street.trim()) {
      throw "street is empty"
    }

    if (!addr.state || !addr.state.trim()) {
      throw "state is empty"
    }

    if (!addr.country || !addr.country.trim()) {
      throw "country is empty"
    }

    if (!addr.city || !addr.city.trim()) {
      throw "city is empty"
    }

    if (!addr.code || !addr.code.trim()) {
      throw "code is empty"
    }

  }



}
