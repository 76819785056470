import { Component, OnChanges, Input } from '@angular/core';
import { ZenduOne } from 'src/typings/app';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { NotifierService } from 'src/app/services/notifier.service';
import { CancelConfirmComponent } from 'src/app/dialogs/cancel-confirm/cancel-confirm.component';
import { CustomerPlatformEditComponent } from '../customer-platform-edit/customer-platform-edit.component';

@Component({
  selector: 'app-customer-platforms-list',
  templateUrl: './customer-platforms-list.component.html',
  styleUrls: ['./customer-platforms-list.component.scss']
})
export class CustomerPlatformsListComponent implements OnChanges {

  @Input() customer: ZenduOne.Customer;

  public isLoading: boolean;

  public dataSource = new MatTableDataSource();

  constructor(
    private _dialog: MatDialog,
    private _notify: NotifierService
  ) { }


  ngOnChanges() {
    this.updateDataSource();
  }

  private updateDataSource() {
    if (!this.customer.platforms) {
      this.dataSource.data = [];
      return;
    }

    this.dataSource.data = this.customer.platforms;
  }

  public async add() {
    let result = await this._dialog.open(CustomerPlatformEditComponent,
      { data: null })
      .afterClosed().toPromise();
    if (!result) {
      return;
    }

    this.customer.platforms.push(result);
    this.updateDataSource();
  }

  public async edit(platform: ZenduOne.ProductPlatform) {
    // open edit dialog
    await this._dialog.open(CustomerPlatformEditComponent,
      { data: platform })
      .afterClosed().toPromise();
  }

  public async remove(platform: ZenduOne.ProductPlatform) {
    try {
      const dialogRef = this._dialog.open(CancelConfirmComponent, {
        width: '350px',
        data: {
          title: "Confirm",
          text: `Are you sure you want to remove platform "${platform.id}"?`
        }
      });
      let confirm = await dialogRef.afterClosed().toPromise();
      if (!confirm) {
        return;
      }

      let idx = this.customer.platforms.indexOf(platform);
      this.customer.platforms.splice(idx, 1);

      this.updateDataSource();
    }
    catch (err) {
      this._notify.error(err);
    }
  }
}
