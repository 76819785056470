import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";

@Injectable()
export class DashboardService {
    constructor(private _httpService: HttpService) { }

    public async loadData(type: string,
        options: {
            dateFrom?: Date,
            dateTo?: Date
        }): Promise<Array<{ name: string, data: Array<{ x: number, y: number }> }>> {
        const data = await this._httpService.post(`dashboard/widget/${type}`, options);
        return data;
    }

}