import { Component, Input, OnChanges } from '@angular/core';
import { ZenduOne } from 'src/typings/app';
import { RouteBillingCustomer, RouteBillingCustom } from 'src/typings/billing';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { BillingService } from 'src/app/services/billing.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { CancelConfirmComponent } from 'src/app/dialogs/cancel-confirm/cancel-confirm.component';
import { Middleware } from 'src/typings/middleware';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-customer-custom-billing',
  templateUrl: './customer-custom-billing.component.html',
  styleUrls: ['./customer-custom-billing.component.scss']
})
export class CustomerCustomBillingComponent implements OnChanges {

  @Input() customer: ZenduOne.Customer;

  @Input() billing: RouteBillingCustomer;

  @Input() initMyAdmin: boolean;

  public isLoading: boolean;

  public dsBilling = new MatTableDataSource();

  public myAdminTypes: Array<{ name: string, defaultPrice: number }>;

  private _myAdminVehicles: Array<Middleware.Device>;

  constructor(
    private _billingService: BillingService,
    private _notify: NotifierService,
    private _customerService: CustomerService,
    private _dialog: MatDialog) {

    // TODO: load from server
    this.myAdminTypes = [
      { name: "Base Mode", defaultPrice: 19 },
      { name: "Pro Mode", defaultPrice: 27 },
      { name: "ProPlus Mode", defaultPrice: 30 },
      { name: "Regulatory Mode", defaultPrice: 22 },
      { name: "Suspend Mode", defaultPrice: 10 },
      { name: "Custom Mode", defaultPrice: 10 }];

  }

  ngOnChanges() {
    this.init();
  }

  public async init() {
    try {
      this.isLoading = true;

      if (!this.billing ||
        !this.customer) {
        return;
      }

      if (!this.billing ||
        !this.billing.custom ||
        !this.billing.custom.length) {

        if (this.initMyAdmin) {
          // initalize my admin
          this.addMyAdmin();
        }

        return;
      }

      this.loadMyAdminVehicles();

      this.updateDataSource();
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }

  }

  private loadMyAdminVehicles() {
    this._myAdminVehicles = null;
    if (this.customer.geotabMyAdminId) {
      this._customerService.getVehicles({
        id: this.customer._id,
        type: "geotab_myadmin"
      })
        .then((res) => {
          this._myAdminVehicles = res;
          this.updateDataSource();
        });
    }
  }

  private updateDataSource() {
    let viewItems = [];
    for (let item of this.billing.custom) {

      let vehiclesCount = "";
      if (item.type == "myadmin" && item.subtype) {
        if (this._myAdminVehicles) {
          vehiclesCount = this._myAdminVehicles
            .filter(v => v.devicePlans && v.devicePlans.indexOf(item.subtype) >= 0)
            .length.toString();
        }
        else {
          vehiclesCount = "...";
        }
      }

      viewItems.push({
        item: item,
        vehicles: vehiclesCount
      })
    }
    this.dsBilling.data = viewItems;
  }

  public async addMyAdmin() {
    try {
      this.isLoading = true;

      for (let type of this.myAdminTypes) {
        this.billing.custom.push({
          type: "myadmin",
          name: `Geotab ${type.name}`,
          subtype: type.name,
          price: type.defaultPrice || 0
        });
      }

      this.loadMyAdminVehicles();

      this.updateDataSource();
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  public addFixed() {
    this.billing.custom.push({
      type: "fixed",
      name: `Fixed`,
      subtype: "",
      price: 0
    });
    this.updateDataSource();
  }

  public async remove(item: RouteBillingCustom) {
    const dialogRef = this._dialog.open(CancelConfirmComponent, {
      width: '350px',
      data: {
        title: "Confirm",
        text: `Are you sure you want to remove "${item.name}"?`
      }
    });
    let confirm = await dialogRef.afterClosed().toPromise();
    if (!confirm) {
      return;
    }

    let idx = this.billing.custom.indexOf(item);
    this.billing.custom.splice(idx, 1);
    this.updateDataSource();
  }

  public async save() {
    try {
      this.isLoading = true;

      if (!this.billing) {
        return;
      }

      await this._billingService.updateCustom(this.billing);
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  public getTypeLabel(type: string) {
    if (!type) {
      return "";
    }
    return type.replace("Mode", "").trim();
  }
}
