import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { AuthService } from "../services/auth.service";

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private _router: Router,
        private _authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Promise<boolean> | boolean {
        if (!this._authService.getToken()) {
            // user is not sign in
            this._router.navigate(['/sign-in']);
            return false;
        }

        if (this._authService.isReseller()) {
            if (!this._authService.isAgreementAccepted()) {
                // user is not accepted agreement
                this._router.navigate(['/agreement']);
                return false;
            }
        }

        return true;
    }

}