import * as moment from "moment";
import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { NotifierService } from 'src/app/services/notifier.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { SeriesColumnOptions, XAxisDateTimeLabelFormatsOptions } from "highcharts";

@Component({
  selector: 'app-dashboard-overview',
  templateUrl: './dashboard-overview.component.html',
  styleUrls: ['./dashboard-overview.component.scss']
})
export class DashboardOverviewComponent implements OnInit {

  public isLoading: boolean;

  public chartTotalInstalls: Chart;

  public chartActivations: Chart;

  public chartBilling: Chart;

  public chartBillingCustomers: Chart;

  public isStartupVisible: boolean;

  constructor(
    private _authService: AuthService,
    private _notify: NotifierService,
    private _dashboardService: DashboardService) {

    if (this._authService.getResellerId()) {
      // startup widget only visible for reseller
      this.isStartupVisible = true;
    }
    else {
      this.isStartupVisible = false;
    }
  }

  ngOnInit() {
    this.init();
  }


  private init() {
    try {
      this.loadTotalInstalls();

      this.loadActivations();

      this.loadBilling();
    }
    catch (err) {
      this._notify.error(err);
    }
  }

  private async loadBilling() {
    try {
      const dateFrom = moment().subtract(12, "month").startOf("month").toDate();
      const dateTo = moment().endOf("month").toDate();
      const items = await this._dashboardService.loadData("billing", {
        dateFrom: dateFrom,
        dateTo: dateTo
      })
      const series = items.map(d => ({
        name: d.name,
        type: "column",
        data: d.data
      }) as SeriesColumnOptions);

      this.chartBilling = new Chart({
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        xAxis: {
          type: "datetime",
          dateTimeLabelFormats: {
            day: '%b',
            month: '%b'
          } as XAxisDateTimeLabelFormatsOptions,
          min: dateFrom.getTime(),
          tickInterval: (30 * 24 * 3600 * 1000)
        },
        yAxis: {
          title: {
            text: "payment (usd)"
          }
        },
        plotOptions: {
          column: {
            stacking: "normal"
          }
        },
        series: series
      });
    }
    catch (err) {
      this._notify.error(err);
    }
  }

  private async loadActivations() {
    try {
      const dateFrom = moment().subtract(12, "month").startOf("month").toDate();
      const dateTo = moment().endOf("month").toDate();
      const items = await this._dashboardService.loadData("activations", {
        dateFrom: dateFrom,
        dateTo: dateTo
      })
      const series = items.map(d => ({
        name: d.name,
        type: "column",
        data: d.data
      }) as SeriesColumnOptions);

      this.chartActivations = new Chart({
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        xAxis: {
          type: "datetime",
          dateTimeLabelFormats: {
            day: '%b',
            month: '%b'
          } as XAxisDateTimeLabelFormatsOptions,
          min: dateFrom.getTime(),
          tickInterval: (30 * 24 * 3600 * 1000)
        },
        yAxis: {
          title: {
            text: "activations"
          }
        },
        plotOptions: {
          column: {
            stacking: "normal"
          }
        },
        series: series
      });
    }
    catch (err) {
      this._notify.error(err);
    }
  }

  private async loadTotalInstalls() {
    try {
      const items = await this._dashboardService.loadData("total_installs", {})
      const data = items.map(d => ({
        name: d.name,
        y: d.data[0].y
      }));
      this.chartTotalInstalls = new Chart({
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        series: [{
          type: "pie",
          data: data
        }]
      });
    }
    catch (err) {
      this._notify.error(err);
    }
  }

  public download(type: string) {
    const dateFrom = moment().subtract(12, "month").startOf("month").toDate();
    const dateTo = moment().endOf("month").toDate();

    const link = document.createElement("a");
    link.target = "_blank";
    (<any>link).download = "";
    link.href = `${environment.service}/dashboard/download/` +
      `${type}?token=${this._authService.getToken()}` +
      `&dateFrom=${dateFrom.toISOString()}` +
      `&dateTo=${dateTo.toISOString()}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
