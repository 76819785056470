import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { RegisterComponent } from './pages/register/register.component';
import { InitalizeComponent } from './pages/initalize/initalize.component';
import { AuthGuard } from './guards/auth.guard';
import { ProductsListComponent } from './pages/products/products-list/products-list.component';
import { ProductEditComponent } from './pages/products/product-edit/product-edit.component';
import { CategoriesListComponent } from './pages/categories/categories-list/categories-list.component';
import { CategoriesEditComponent } from './pages/categories/categories-edit/categories-edit.component';
import { DashboardOverviewComponent } from './pages/dashboard/dashboard-overview/dashboard-overview.component';
import { DefaultContainerComponent } from './pages/default-container/default-container.component';
import { ResellersListComponent } from './pages/resellers/resellers-list/resellers-list.component';
import { ResellersEditComponent } from './pages/resellers/resellers-edit/resellers-edit.component';
import { VechiclesListComponent } from './pages/vehicles/vechicles-list/vechicles-list.component';
import { UsersEditComponent } from './pages/users/users-edit/users-edit.component';
import { CustomersListComponent } from './pages/customers/customers-list/customers-list.component';
import { UsersListComponent } from './pages/users/users-list/users-list.component';
import { CustomersEditComponent } from './pages/customers/customer-edit/customer-edit.component';
import { CustomerPlatformEditComponent } from './pages/customers/components/customer-platform-edit/customer-platform-edit.component';
import { CustomerVehicleEditComponent } from './pages/customers/components/customer-vehicle-edit/customer-vehicle-edit.component';
import { CustomerProductInstallComponent } from './pages/customers/components/customer-product-install/customer-product-install.component';
import { ProductPlanEditComponent } from './pages/products/components/product-plan-edit/product-plan-edit.component';
import { AuditListComponent } from './pages/audit/audit-list/audit-list.component';
import { ResellerSettingsComponent } from './pages/reseller-settings/reseller-settings.component';
import { StripeOauthComponent } from './pages/stripe-oauth/stripe-oauth.component';
import { AgreementComponent } from './pages/agreement/agreement.component';
import { StripeAccountConnectComponent } from './pages/stripe-account-connect/stripe-account-connect.component';
import { CustomerProductsComponent } from './pages/customer-products/customer-products.component';
import { BillingComponent } from './pages/billing/billing.component';
import { RegistrationFormComponent } from './pages/registration/registration-form/registration-form.component';
import { ZohoProfileComponent } from './pages/zoho/zoho-profile/zoho-profile.component';


const routes: Routes = [
    {
        path: 'management', canActivate: [AuthGuard],
        children: [
            {
                path: 'dashboard',
                component: DefaultContainerComponent,
                children: [
                    { path: '', redirectTo: 'overview', pathMatch: "full" },
                    { path: 'overview', component: DashboardOverviewComponent }
                ]
            },
            {
                path: 'product',
                component: DefaultContainerComponent,
                children: [
                    { path: '', redirectTo: 'list', pathMatch: "full" },
                    { path: 'list', component: ProductsListComponent },
                    { path: 'edit', component: ProductEditComponent },
                    { path: 'plan', component: ProductPlanEditComponent }
                ]
            },
            {
                path: 'category',
                component: DefaultContainerComponent,
                children: [
                    { path: '', redirectTo: 'list', pathMatch: "full" },
                    { path: 'list', component: CategoriesListComponent },
                    { path: 'edit', component: CategoriesEditComponent }
                ]
            },
            {
                path: 'customer',
                component: DefaultContainerComponent,
                children: [
                    { path: '', redirectTo: 'list', pathMatch: "full" },
                    { path: 'list', component: CustomersListComponent },
                    { path: 'edit', component: CustomersEditComponent },
                    // platform editing/creation
                    { path: 'platform', component: CustomerPlatformEditComponent },
                    // vehicle editing/creation
                    { path: 'vehicle', component: CustomerVehicleEditComponent },
                    // app editing/creation
                    { path: 'app', component: CustomerProductInstallComponent }
                ]
            },
            {
                path: 'reseller',
                component: DefaultContainerComponent,
                children: [
                    { path: '', redirectTo: 'list', pathMatch: "full" },
                    { path: 'list', component: ResellersListComponent },
                    { path: 'edit', component: ResellersEditComponent }
                ]
            },
            {
                path: 'customer_products',
                component: DefaultContainerComponent,
                children: [
                    { path: '', redirectTo: 'list', pathMatch: "full" },
                    { path: 'list', component: CustomerProductsComponent }
                ]
            },
            {
                path: 'vehicle',
                component: DefaultContainerComponent,
                children: [
                    { path: '', redirectTo: 'list', pathMatch: "full" },
                    { path: 'list', component: VechiclesListComponent }
                ]
            },
            {
                path: 'user',
                component: DefaultContainerComponent,
                children: [
                    { path: '', redirectTo: 'list', pathMatch: "full" },
                    { path: 'list', component: UsersListComponent },
                    { path: 'edit', component: UsersEditComponent }
                ]
            },
            {
                path: 'billing',
                component: DefaultContainerComponent,
                children: [
                    { path: '', redirectTo: 'list', pathMatch: "full" },
                    { path: 'list', component: BillingComponent }
                ]
            },
            {
                path: 'audit',
                component: DefaultContainerComponent,
                children: [
                    { path: '', redirectTo: 'list', pathMatch: "full" },
                    { path: 'list', component: AuditListComponent }
                ]
            },
            {
                path: 'reseller-settings',
                component: DefaultContainerComponent,
                children: [
                    { path: '', component: ResellerSettingsComponent }
                ]
            },
        ]
    },
    { path: 'sign-in', component: SignInComponent },
    { path: 'stripe-oauth', component: StripeOauthComponent },
    { path: 'agreement', component: AgreementComponent },
    { path: 'stripe-account-connect', component: StripeAccountConnectComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'initalize', component: InitalizeComponent },
    { path: 'sign-up', component: RegistrationFormComponent },
    { path: 'zoho/profile', component: ZohoProfileComponent },
    { path: '**', redirectTo: 'initalize' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }