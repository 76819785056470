export class UserPermissions
{
    public static management ={
        read: "managment.read",
        write: "managment.write"
    }

    public static sales ={
        read: "sales.read",
        write: "sales.write"
    }

    public static marketplace ={
        read: "marketplace.read",
        write: "marketplace.write"
    }
}