import { Component, OnInit, ViewChild } from '@angular/core';
import { ZenduOne } from 'src/typings/app';
import { MatPaginator } from '@angular/material';
import { NotifierService } from 'src/app/services/notifier.service';
import { BillingService } from 'src/app/services/billing.service';
import { ResellerService } from 'src/app/services/reseller.service';
import { environment } from 'src/environments/environment';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {

  @ViewChild("paginator") paginator: MatPaginator;

  public isLoading: boolean;

  public resellers: ZenduOne.Reseller[];

  public selResellers: ZenduOne.Reseller[];

  public customers: ZenduOne.Customer[];

  public selCustomers: ZenduOne.Customer[];

  public dropdownSettings;

  private _timerChanges;

  public items = [];

  private _searchTimer;

  constructor(
    private _notify: NotifierService,
    private _billingService: BillingService,
    private _resellerService: ResellerService,
    private _customerService: CustomerService
  ) {
  }

  ngOnInit() {
    this.init();
  }

  private async init() {
    try {
      this.isLoading = true;

      this.dropdownSettings = {
        singleSelection: false,
        idField: '_id',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        allowSearchFilter: true,
        clearSearchFilter: true
      }

      this.resellers = await this._resellerService.find();
      this.customers = await this._customerService.find({});

      await this.updatePagingCount();

      setTimeout(() => {
        // run first update with timeout after paginator initalization
        this.update();

        // subscribe to paginator changes
        this.paginator.page.subscribe(() => {
          this.update();
        })
      }, 100);
    }
    catch (err) {
      this._notify.error(err);
    }
  }

  public updateChanges() {
    clearTimeout(this._timerChanges);
    this._timerChanges = setTimeout(() => {
      this.update(true);
    }, 1000)
  }

  private async updatePagingCount() {
    const { resellersIds, customerIds } = this.getSearchOptions();
    this.paginator.length = await this._billingService.installationsCount({
      resellerIds: resellersIds,
      customerIds: customerIds
    });
  }

  private async update(updatePageCount?: boolean) {
    try {
      this.isLoading = true;

      if (updatePageCount) {
        await this.updatePagingCount();
      }

      const { resellersIds, customerIds } = this.getSearchOptions();

      this.items = await this._billingService.findInstallations({
        resellerIds: resellersIds,
        customerIds: customerIds,
        // customerName: this.filter.name ? `%${this.filter.name}%` : "",
        offset: (this.paginator.pageIndex * this.paginator.pageSize) || 0,
        limit: this.paginator.pageSize
      });
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  private getSearchOptions() {
    let resellersIds: string[];
    if (this.selResellers && this.selResellers.length) {
      resellersIds = this.selResellers.map(r => r._id);
    }
    let customerIds: string[];
    if (this.selCustomers && this.selCustomers.length) {
      customerIds = this.selCustomers.map(r => r._id);
    }
    return { resellersIds, customerIds };
  }

  public search() {
    clearTimeout(this._searchTimer);
    this._searchTimer = setTimeout(() => {
      this.paginator.firstPage();
      this.update();
    }, 1000);
  }

  public export() {
    const { resellersIds, customerIds } = this.getSearchOptions();
    this._billingService.installationsExport({
      resellerIds: resellersIds,
      customerIds: customerIds
    });
  }
}
