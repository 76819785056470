export class UserRoleName {
    public static admin = "console.admin";

    public static adminViewer = "console.admin.viewer";

    public static sales = "console.sales";

    public static reseller = "console.reseller";

    public static customer = "customer.user";

    public static service = "service.api";

    public static partnerApi = "partner.api";
}