import { Component, OnInit, Optional, Inject } from '@angular/core';
import { ZenduOne } from 'src/typings/app';
import { NotifierService } from 'src/app/services/notifier.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from 'src/app/services/customer.service';
import { PlatformService } from 'src/app/services/platform.service';
import { MatDialog } from '@angular/material';
import { CancelConfirmComponent } from 'src/app/dialogs/cancel-confirm/cancel-confirm.component';
import { environment } from 'src/environments/environment.local';

@Component({
  selector: 'app-customer-platform-edit',
  templateUrl: './customer-platform-edit.component.html',
  styleUrls: ['./customer-platform-edit.component.scss']
})
export class CustomerPlatformEditComponent implements OnInit {

  public isLoading: boolean;

  public customer: ZenduOne.Customer;

  public platform: ZenduOne.ProductPlatform;

  public isNew: boolean;

  public payload: {
    database?: string,
    server?: string
  };

  public createNewDatabase: boolean;

  constructor(
    private _notify: NotifierService,
    private _activeRoute: ActivatedRoute,
    private _customerService: CustomerService,
    private _platformService: PlatformService,
    private _router: Router,
    private _dialog: MatDialog
  ) {
    this.payload = {};
  }

  ngOnInit() {
    this.update();
  }

  private async update() {
    try {
      this.isLoading = true;

      let query = this._activeRoute.snapshot.queryParams;
      let customerId = query.customerId;
      let platformId = query.platformId;
      if (!customerId) {
        throw "customerId is empty"
      }
      this.customer = await this._customerService.findOne(customerId);
      if (!this.customer) {
        throw `invalid customer id "${customerId}"`
      }

      if (platformId) {
        this.platform = this.customer.platforms.find(p => p.id == platformId);
        if (!this.platform) {
          throw `invalid platform id "${platformId}"`
        }

        // load payload from service
        //
        let data = await this._platformService.findOne({
          customerId: customerId,
          platformId: platformId
        })
        if (data && data.payload) {
          this.payload = data.payload;
        }
        else {
          this.payload = { database: this.platform.database };
        }

        this.isNew = false;
      }
      else {
        this.platform = {
          id: "",
          required: false,
          database: ""
        }
        this.isNew = true;
      }
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  public async save() {
    try {
      this.isLoading = true;

      if (!this.platform.id) {
        throw `Id is empty`
      }

      // assign database name from payload
      this.platform.database = this.payload.database;

      if (this.isNew) {
        await this._platformService.add({
          customerId: this.customer._id,
          platform: this.platform,
          payload: this.payload,
          createNewDatabase: this.createNewDatabase
        });

        this._notify.success("Platform created");

        // go to a customer page
        this._router.navigate(["/management/customer/edit"], { queryParams: { id: this.customer._id } });
      }
      else {
        await this._platformService.update({
          customerId: this.customer._id,
          platform: this.platform,
          payload: this.payload
        })

        this._notify.success("Platform updated");
      }
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  public async remove() {
    try {
      const dialogRef = this._dialog.open(CancelConfirmComponent, {
        width: '350px',
        data: {
          title: "Confirm",
          text: `Are you sure you want to remove platform "${this.platform.id}"?`
        }
      });
      let confirm = await dialogRef.afterClosed().toPromise();
      if (!confirm) {
        return;
      }

      this.isLoading = true;

      await this._platformService.remove({
        customerId: this.customer._id,
        platformId: this.platform.id
      });

      this._router.navigate(["/management/customer/edit"], { queryParams: { id: this.customer._id } });
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  public login() {
    let loginUrl = this._platformService.getLoginUrl(this.customer);
    window.open(loginUrl, "_blank")
  }
}

