import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { ZenduOne } from "src/typings/app";
import { Middleware } from "src/typings/middleware";

@Injectable()
export class CustomerService {
    constructor(private _httpService: HttpService) { }

    public async findOne(id: string): Promise<ZenduOne.Customer> {
        let items = await this._httpService.post("customers/find", { id: id });
        return items[0];
    }

    public async find(options: {
        name?: string,
        resellerId?: string,
        platform?: { id?: string, database?: string },
        zohoAccountId?: string,
        email?: string,
        id?: string,
        ids?: string[],
        offset?: number,
        limit?: number
    }): Promise<Array<ZenduOne.Customer>> {
        let items = await this._httpService.post("customers/find", options);
        return items;
    }

    public async count(): Promise<number> {
        let res = await this._httpService.post("customers/count");
        return res.count;
    }

    public async update(options: {
        customer: ZenduOne.Customer,
        options: {
            assignZohoAccount: boolean,
            assignMyAdminAccount: boolean,
            creditCardToken: string
        }
    }) {
        await this._httpService.post("customers/update", options);
    }

    public async add(options: {
        customer: ZenduOne.Customer,
        assignZohoAccount: boolean,
        creditCardToken: string
    }): Promise<{ id: string, tokenId: string }> {
        return await this._httpService.post("customers/add", options);
    }

    public async getUsers(search: {
        id: string,
        type: string
    }): Promise<Array<Middleware.User>> {
        return await this._httpService.post("customers/getUsers", search);
    }

    public async getVehicles(search: {
        id: string,
        type: string
    }): Promise<Array<Middleware.Device>> {
        return await this._httpService.post("customers/getVehicles", search);
    }

    /**
     * Get custom parameter values for installed app
     */
    public async getCustomParams(search: {
        id: string,
        type: string
    }): Promise<Array<Middleware.ConfigureCustomParameter>> {
        return await this._httpService.post("customers/getCustomParams", search);
    }

    public async findCreditCard(customerId: string): Promise<ZenduOne.CustomerCreditCard> {
        let item = await this._httpService.post("customers/creditcard/find", { customerId: customerId });
        return item;
    }
}