import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NotifierService } from 'src/app/services/notifier.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  public isLoading: boolean;

  public username: string;

  public password: string;

  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _notifier: NotifierService) { }

  ngOnInit() {
  }

  public async signIn(data) {
    try {
      this.isLoading = true;

      await this._authService.forgot();

      const credentials = {
        username: data.value.username,
        password: data.value.password
      };
      await this._authService.loginWithPassword(credentials);

      this.redirectToSite();
    }
    catch (err) {
      this._notifier.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  private redirectToSite() {
    if (this._activatedRoute.snapshot.queryParams.backurl) {
      document.location.href = this._activatedRoute.snapshot.queryParams.backurl;
    }
    else {
      this._router.navigate(["/managment/dashboard"]);
    }
  }

}
