import { Component, OnInit } from '@angular/core';
import { ZenduOne } from 'src/typings/app';
import { ResellerService } from 'src/app/services/reseller.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { FileHolder } from 'angular2-image-upload';
import { BillingService } from 'src/app/services/billing.service';
import { MatTableDataSource } from '@angular/material';
import { ProductsService } from 'src/app/services/products.service';
import { CountryService } from 'src/app/services/country.service';

@Component({
    selector: 'app-resellers-edit',
    templateUrl: './resellers-edit.component.html',
    styleUrls: ['./resellers-edit.component.scss']
})
export class ResellersEditComponent implements OnInit {

    public reseller: ZenduOne.Reseller;

    public isLoading: boolean;

    public uploadUrl: string;

    public headers: {};

    public isPricingLoading: boolean;

    public priceSource = new MatTableDataSource();

    public productAccess: Array<{ product: ZenduOne.Product, enabled: boolean }>

    public countries: Array<{ id: string, name: string }>

    private _timer;

    constructor(
        private _notify: NotifierService,
        private _activeRoute: ActivatedRoute,
        private _resellerService: ResellerService,
        private _router: Router,
        private _authService: AuthService,
        private _billingService: BillingService,
        private _productService: ProductsService,
        private _countryService: CountryService
    ) {
        this.uploadUrl = `${environment.service}/cloudstrorage/upload`
        this.headers = {
            'Authorization': this._authService.getToken()
        };
        this.productAccess = [];
        this.countries = this._countryService.getList();
    }

    ngOnInit() {
        this.init();
    }

    private async init() {
        try {
            this.isLoading = true;

            const products = await this._productService.find({});

            let query = this._activeRoute.snapshot.queryParams;
            if (query.id) {
                // load exist reseller
                this.reseller = await this._resellerService.findOne(query.id);

                // update pricing
                this.updatePricing();
            }
            else {
                // new reseller
                this.reseller = {
                    discount: 20,
                    extraCharge: 0,
                    name: "New Reseller",
                    paymentMode: "customer",
                    email: "",
                    billingEmail: "",
                    contactName: "",
                    phone: "",
                    customization: {},
                    productsAccess: products.map(p => ({ productId: p._id, enabledForCustomer: true }))
                }
            }

            if (!this.reseller.address) {
                // setup default address
                this.reseller.address = {
                    country: "USA"
                };
            }

            if (!this.reseller.platforms) {
                this.reseller.platforms = [];
            }

            // update app access
            this.updateAppAccess(products);

            if (!this.reseller.customization) {
                // init customization property for binding
                this.reseller.customization = {}
            }
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    private updateAppAccess(products: ZenduOne.Product[]) {
        this.productAccess = [];
        if (!this.reseller.productsAccess) {
            // initalize products access
            this.reseller.productsAccess = [];
        }
        for (let product of products) {
            const enabled = this.reseller.productsAccess.find(p => p.productId == product._id) ? true : false;
            this.productAccess.push({ product: product, enabled: enabled });
        }
    }

    public async save() {
        try {
            this.isLoading = true;

            this.prepareForUpdate();

            if (!this.reseller._id) {
                // add a new reseller
                let id = await this._resellerService.add(this.reseller);
                this.reseller._id = id;
                // update uri
                this._router.navigate(["/management/reseller/edit"], { queryParams: { id: id } });
            }
            else {
                // update exist reseller
                await this._resellerService.update(this.reseller);
            }

            this._notify.success("Reseller saved")
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    private prepareForUpdate() {
        // update app access field
        let targetAccess = new Array<{ productId: string; enabledForCustomer: boolean }>();
        for (let prodAccess of this.productAccess) {
            if (!prodAccess.enabled) {
                continue;
            }
            let resellerApp = this.reseller.productsAccess.find(p => p.productId == prodAccess.product._id);
            let enabledForCustomer = resellerApp ? resellerApp.enabledForCustomer : true;
            targetAccess.push({ productId: prodAccess.product._id, enabledForCustomer: enabledForCustomer });
        }
        this.reseller.productsAccess = targetAccess;
    }

    public onUploadFinished(data: FileHolder, type: string) {
        if (data &&
            data.serverResponse &&
            data.serverResponse.response &&
            data.serverResponse.response.body) {
            let resp = JSON.parse(data.serverResponse.response.body);
            if (resp.url) {
                if (type == "loginLogo") {
                    this.reseller.customization.loginLogo = resp.url;
                }
                else if (type == "headerLogo") {
                    this.reseller.customization.headerLogo = resp.url;
                }
                else {
                    console.error(`unknown type: ${type}`);
                }
            }
            else {
                this._notify.error("Upload image failed");
            }
        }
    }

    public getPaymentPercent() {
        if (!this.reseller) {
            return 0;
        }
        return 100 - this.reseller.discount;
    }

    public isNeedConnect() {
        if (!this.reseller._id) {
            return false;
        }
        if (this.reseller.paymentMode != "customer") {
            return false;
        }
        return !this.reseller.stripeAccountId;
    }


    public async updatePricing() {
        clearTimeout(this._timer);
        this._timer = setTimeout(async () => {

            if (typeof this.reseller.discount != "number" ||
                typeof this.reseller.extraCharge != "number") {
                // skip pricing calculate for invalid value
                return;
            }

            try {
                this.isPricingLoading = true;
                this.priceSource.data = [];
                let items = await this._billingService.calcResellerPricing({
                    discount: this.reseller.discount,
                    extraCharge: this.reseller.extraCharge
                });
                this.priceSource.data = items;
            }
            catch (err) {
                this._notify.error(err);
            }
            finally {
                this.isPricingLoading = false;
            }

        }, 1000)
    }

    public toggleProductAccess() {
        let selCount = this.productAccess.filter(p => p.enabled).length;
        if (selCount > 0) {
            this.productAccess.map(p => p.enabled = false)
        }
        else {
            this.productAccess.map(p => p.enabled = true)
        }
    }

    public addPlatform() {
        this.reseller.platforms.push({
            type: ""
        })
    }

    public deletePlatform(platform: ZenduOne.ResellerPlatform) {
        const idx = this.reseller.platforms.indexOf(platform);
        this.reseller.platforms.splice(idx, 1);
    }
}
