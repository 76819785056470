import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { ZenduOne } from "src/typings/app";

@Injectable()
export class RoleService {
    constructor(private _httpService: HttpService) { }

    public async findOne(name: string): Promise<ZenduOne.Role> {
        let items = await this._httpService.post("roles/find", { name: name });
        return items[0];
    }

    public async find(): Promise<Array<ZenduOne.Role>> {
        let items = await this._httpService.post("roles/find", {});
        return items;
    }
}