import { Component, OnInit } from '@angular/core';
import { ResellerService } from 'src/app/services/reseller.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { AuthService } from 'src/app/services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard-startup',
  templateUrl: './dashboard-startup.component.html',
  styleUrls: ['./dashboard-startup.component.scss']
})
export class DashboardStartupComponent implements OnInit {

  public isCustomizeDone: boolean;

  public isStripeDone: boolean;

  public percent: number;

  public chartOffset: number;

  public isLoading: boolean;

  public username: string;

  public isStepsClosed: boolean;

  public isTutorialClosed: boolean;

  public clientId: string;

  constructor(
    private _resellerService: ResellerService,
    private _authService: AuthService,
    private _notify: NotifierService,
    private _cookies: CookieService) {

    this.init()
  }

  ngOnInit() {
  }

  private async init() {
    try {
      this.isLoading = true;

      this.username = this._authService.getUsername();
      this.clientId = environment.stripeClientId;
      this.isStepsClosed = this._cookies.get("dashboard-startup-steps") == "closed";
      this.isTutorialClosed = this._cookies.get("dashboard-startup-tutorial") == "closed";

      this.percent = 33;
      this.chartOffset = 80;

      let configuration = await this._resellerService.findConfiguration();
      if (configuration.headerLogo ||
        configuration.loginLogo ||
        configuration.portalHeaderColor ||
        configuration.portalHeaderFontColor) {
        // customization is done
        this.isCustomizeDone = true;
        this.percent = 66;
        this.chartOffset = 40;
      }

      if (!this._authService.isStripeConnectRequired()) {
        // stripe account is connected
        this.isStripeDone = true;
        this.percent = 100;
        this.chartOffset = 0;
      }
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  public closeSteps() {
    this._cookies.set("dashboard-startup-steps", "closed", 0, "/")
    this.isStepsClosed = true;
  }

  public closeTutorial() {
    this._cookies.set("dashboard-startup-tutorial", "closed", 0, "/")
    this.isTutorialClosed = true;
  }
}
