import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './app-navbar.component.html',
  styleUrls: ['./app-navbar.component.scss']
})
export class AppNavbarComponent implements OnInit {

  @Output() search: EventEmitter<string> = new EventEmitter();

  public username: string;

  public type: string;

  constructor(
    private _auth: AuthService
  ) {
    this.username = this._auth.getUsername();
    if (this._auth.isAdmin()) {
      this.type = "Admin";
    }
    else if (this._auth.isReseller()) {
      this.type = "Reseller";
    }
  }

  ngOnInit() {
  }

}
