import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { ZenduOne } from "src/typings/app";

@Injectable()
export class UserService {
    constructor(private _httpService: HttpService) { }

    public async findOne(id: string): Promise<ZenduOne.User> {
        let items = await this._httpService.post("users/find", { id: id });
        return items[0];
    }

    public async find(search: ZenduOne.UserSearch): Promise<Array<ZenduOne.User>> {
        let items = await this._httpService.post("users/find", search);
        return items;
    }

    public async count(search: ZenduOne.UserSearch): Promise<number> {
        let data = await this._httpService.post("users/count", search);
        return data.count;
    }

    public async update(user: ZenduOne.User) {
        await this._httpService.post("users/update", user);
    }

    public async add(user: ZenduOne.User): Promise<string> {
        let res = await this._httpService.post("users/add", user);
        return res.id;
    }

    public async resetPassword(userId: string) {
        await this._httpService.post("users/resetPassword", { id: userId });
    }

    public async remove(id: string) {
        await this._httpService.post("users/remove", { id: id });
    }

    public async acceptAgreement() {
        await this._httpService.post("users/acceptAgreement", {});
    }
}