import { Component, OnInit, Inject, Optional } from '@angular/core';
import { NotifierService } from 'src/app/services/notifier.service';
import { ZenduOne } from 'src/typings/app';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { FileHolder } from 'angular2-image-upload';

@Component({
  selector: 'app-product-feature-edit',
  templateUrl: './product-feature-edit.component.html',
  styleUrls: ['./product-feature-edit.component.scss']
})
export class ProductFeatureEditComponent implements OnInit {

  public item: ZenduOne.ProductFeature;

  public uploadUrl: string;

  public isLoading: boolean;

  public headers: {};

  constructor(
    @Optional() private _taskDialog: MatDialogRef<ZenduOne.ProductFeature>,
    @Inject(MAT_DIALOG_DATA) public data: ZenduOne.ProductFeature,
    private _notify: NotifierService,
    private _authService: AuthService
  ) {
    this.item = data ? data : {
      icon: "",
      name: "",
      text: ""
    };

    this.uploadUrl = `${environment.service}/cloudstrorage/upload`
    this.headers = {
      'Authorization': this._authService.getToken()
    };
  }

  ngOnInit() {
  }

  public save() {
    try {
      if (!this.item.name) {
        throw `Name is empty`
      }
      if (!this.item.text) {
        throw `Text is empty`
      }
      if (!this.item.icon) {
        throw `Icon is empty`
      }

      this._taskDialog.close(this.item);
    }
    catch (err) {
      this._notify.error(err);
    }
  }

  
  public onUploadIcon(data: FileHolder) {
    let url = this.getUploadedUrl(data);
    if (url) {
      this.item.icon = url;
    }
    else {
      this._notify.error("Upload failed");
    }
  }
  
  private getUploadedUrl(data: FileHolder) {
    if (data &&
      data.serverResponse &&
      data.serverResponse.response &&
      data.serverResponse.response.body) {
      let resp = JSON.parse(data.serverResponse.response.body);
      if (resp.url) {
        return resp.url;
      }
    }
    return "";
  }

}
