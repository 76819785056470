import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ZenduOne } from 'src/typings/app';
import { NotifierService } from 'src/app/services/notifier.service';
import { ProductsService } from 'src/app/services/products.service';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { CustomerAppSelectComponent } from '../customer-app-select/customer-app-select.component';
import { Router } from '@angular/router';
import { Middleware } from 'src/typings/middleware';
import { BillingService } from 'src/app/services/billing.service';
import { RouteBillingCustomer } from 'src/typings/billing';

@Component({
  selector: 'app-customer-apps-list',
  templateUrl: './customer-apps-list.component.html',
  styleUrls: ['./customer-apps-list.component.scss']
})
export class CustomersAppsListComponent implements OnChanges {

  @Input() customer: ZenduOne.Customer;

  public isLoading: boolean;

  private _products: ZenduOne.Product[];

  private _billing: RouteBillingCustomer;

  public dataSource = new MatTableDataSource();

  constructor(
    private _notify: NotifierService,
    private _productsService: ProductsService,
    private _billingService: BillingService,
    private _dialog: MatDialog,
    private _router: Router
  ) { }


  ngOnChanges() {
    this.update();
  }

  public async add() {

    let ignoreList = this._billing ? this._billing.products.map(p => p.id) : []
    // open select dialog
    let product = await this._dialog.open(CustomerAppSelectComponent,
      {
        data: {
          ignore: ignoreList
        }
      })
      .afterClosed().toPromise() as ZenduOne.Product;
    if (!product) {
      return;
    }

    this._router.navigate(["/management/customer/app"],
      {
        queryParams: {
          id: product._id,
          customerId: this.customer._id
        }
      })
  }

  private async update() {
    try {

      if (!this.customer) {
        this.dataSource.data = [];
        return;
      }

      this.isLoading = true;

      if (!this._products) {
        this._products = await this._productsService.find({});
      }

      if (!this._billing) {
        this._billing = await this._billingService.findCustomerBilling({ customerId: this.customer._id });
      }

      if (!this._billing || !this._billing.products) {
        this.dataSource.data = [];
        return;
      }

      // create a view items
      let productViews = [];
      for (let customerProduct of this._billing.products) {
        let product = this._products.find(p => p._id == customerProduct.id);
        if (!product) {
          continue;
        }

        productViews.push({
          id: product._id,
          name: product.name,
          icon: product.content.logo,
          status: customerProduct.active ? "Active" : "Suspend",
          plan: customerProduct.planId,
          vehicles: 0,
          users: 0,
        })
      }
      this.dataSource.data = productViews;
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

}
