import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { NotifierService } from 'src/app/services/notifier.service';
import { AuditService } from 'src/app/services/audit.service';
import { ZenduOne } from 'src/typings/app';
import * as moment from 'moment';

@Component({
  selector: 'app-audit-list',
  templateUrl: './audit-list.component.html',
  styleUrls: ['./audit-list.component.scss']
})
export class AuditListComponent implements OnInit {
  @ViewChild("paginator") paginator: MatPaginator;

  public isLoading: boolean;

  public filter: { username?: string, customer?: string } = {}

  public dataSource = new MatTableDataSource();

  public dateRange: { start: moment.Moment, end: moment.Moment };

  public items: ZenduOne.AuditLog[];

  public options: any;

  private _searchTimer;

  constructor(
    private _notify: NotifierService,
    private _auditService: AuditService
  ) { }

  ngOnInit() {
    this.dateRange = {
      start: moment().startOf('day'),
      end: moment().endOf('day')
    };

    this.options = {
      startDate: this.dateRange.start,
      endDate: this.dateRange.end,
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    };

    this.update();
  }

  private async update() {
    try {
      this.isLoading = true;

      this.items = [];

      this.items = await this._auditService.find({
        dateFrom: this.dateRange.start.toDate(),
        dateTo: this.dateRange.end.toDate(),
        username: this.filter.username ? `%${this.filter.username}%` : "",
        customer: this.filter.customer ? `%${this.filter.customer}%` : "",
      });

      this.dataSource.data = this.items;

      // update datasource after timeout
      // paginators is not exists while loading
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
      }, 100)
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  public dateRangeChanged(ev) {
    this.dateRange.start = ev.start;
    this.dateRange.end = ev.end;

    this.update();
  }

  public applyActionFilter(filterValue: string) {
    if (filterValue) {
      this.dataSource.data = this.items.filter(i =>
        i.readonly &&
        i.readonly.description &&
        i.readonly.description.toLowerCase().includes(filterValue.toLowerCase()))
    }
    else {
      this.dataSource.data = this.items;
    }
    this.paginator.pageIndex = 0;
  }

  public filterChanged() {
    clearTimeout(this._searchTimer)
    this._searchTimer = setTimeout(() => {
      this.update();
    }, 1000);
  }

}
