export class VehicleType{
    public static geotab = "geotab"

    public static smartwitness = "smartwitness"

    public static solar_xt = "solar_xt"

    public static mobile = "mobile"

    public static bewhere = "bewhere"

    public static globalstar = "globalstar"

    public static lonedefender = "lonedefender"

    public static keeptruckin = "keeptruckin"

    public static samsara = "samsara"

    public static custom = "custom"
}