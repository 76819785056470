import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { ZenduOne } from "src/typings/app";

@Injectable()
export class CategoryService {
    constructor(private _httpService: HttpService) { }

    public async findOne(id: string): Promise<ZenduOne.Category> {
        return await this._httpService.post(`products_categories/findOne`, {
            id: id
        });
    }

    public async find(): Promise<Array<ZenduOne.Category>> {
        return await this._httpService.post(`products_categories/find`);
    }

    public async update(category: ZenduOne.Category) {
        await this._httpService.post(`products_categories/update`, category);
    }

    public async add(category: ZenduOne.Category) : Promise<{ id : string }> {
        return await this._httpService.post(`products_categories/add`, category);
    }

    public async remove(id : string) {
        await this._httpService.post(`products_categories/remove`, { id: id });
    }
}