import { Component, OnInit } from '@angular/core';
import { ZenduOne } from 'src/typings/app';
import { NotifierService } from 'src/app/services/notifier.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';
import { CancelConfirmComponent } from 'src/app/dialogs/cancel-confirm/cancel-confirm.component';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { BillingType } from 'src/typings/billingType';
import { VehicleType } from 'src/typings/vehiclesType';

@Component({
  selector: 'app-product-plan-edit',
  templateUrl: './product-plan-edit.component.html',
  styleUrls: ['./product-plan-edit.component.scss']
})
export class ProductPlanEditComponent implements OnInit {

  public isLoading: boolean;

  public plan: ZenduOne.ProductPlan;

  public product: ZenduOne.Product;

  public isNew: boolean;

  public dsBilling = new MatTableDataSource();

  public dsFeatures = new MatTableDataSource();

  public billingTypes: Array<{ id: string }>;

  public vehiclesTypes: Array<{ id: string }>;

  constructor(
    private _notify: NotifierService,
    private _activeRoute: ActivatedRoute,
    private _router: Router,
    private _productService: ProductsService,
    private _dialog: MatDialog
  ) {
    // billing types
    this.billingTypes = [];
    this.billingTypes.push({ id: BillingType.fixed });
    this.billingTypes.push({ id: BillingType.users });
    this.billingTypes.push({ id: BillingType.vehicles });

    // vehicles types
    this.vehiclesTypes = [];
    this.vehiclesTypes.push({ id: "" });
    for (let key of Object.keys(VehicleType)) {
      this.vehiclesTypes.push({ id: VehicleType[key] });
    }
  }

  ngOnInit() {
    this.init();
  }

  private async init() {
    try {
      this.isLoading = true;

      let query = this._activeRoute.snapshot.queryParams;
      if (!query.productId) {
        throw "productId is empty"
      }
      this.product = await this._productService.findOne(query.productId);
      if (!this.product) {
        throw `invalid product id "${query.productId}"`
      }

      if (query.id) {
        this.plan = this.product.plans.find(p => p.id == query.id)
        if (!this.plan) {
          throw `invalid plan id "${query.id}"`
        }
        if(!this.plan.trial){
          // create default trial
          this.plan.trial = {
            enabled: false,
            period: 30
          }
        }
        this.isNew = false;
      }
      else {
        this.plan = this.getNewPlan()
        this.isNew = true;
      }

      if (!this.plan.features) {
        this.plan.features = [];
      }

      // updated data source
      this.dsBilling.data = this.plan.billing;
      this.dsFeatures.data = this.plan.features;
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  private getNewPlan(): ZenduOne.ProductPlan {
    return {
      id: "",
      name: "",
      description: "",
      billing: [],
      trial: {
        enabled: true,
        period: 30
      }
    }
  }

  public async remove() {
    try {
      let confirm = await this._dialog.open(CancelConfirmComponent, {
        width: '350px',
        data: {
          title: "Confirm",
          text: `Are you sure would like to delete "${this.plan.name}" ?`
        }
      }).afterClosed().toPromise();
      if (!confirm) {
        return;
      }

      this.isLoading = true;

      let idx = this.product.plans.indexOf(this.plan)
      this.product.plans.splice(idx, 1);

      await this._productService.update(this.product);

      this._router.navigate(["/management/product/edit"], { queryParams: { id: this.product._id } });
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }


  public async save(next: boolean) {
    try {
      if (!this.plan.id) {
        throw `Id is empty`
      }

      if (!this.plan.name) {
        throw `Name is empty`
      }

      this.isLoading = true;


      if (this.isNew) {
        if (this.product.plans.indexOf(this.plan) < 0) {
          this.product.plans.push(this.plan);
        }
        this.handlePlanFeatures();

        await this._productService.update(this.product);
        this._notify.success("Plan created")

        if (next) {
          this.isNew = true;
          this.plan = this.getNewPlan();
        }
        else {
          this.isNew = false;
        }
      }
      else {
        this.handlePlanFeatures();

        await this._productService.update(this.product);
        this._notify.success("Plan updated")
      }
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  private handlePlanFeatures() {
    for (let plan of this.product.plans) {
      if (!plan.features) {
        plan.features = [];
      }
      for (let feature of plan.features) {
        if (feature.valueType == "number") {
          if (feature.value) {
            feature.value = parseFloat(feature.value.toString());
          }
        }
      }
    }
  }

  public addBilling() {

    this.plan.billing.push({
      name: "Default",
      featureId: "",
      price: 0,
      type: BillingType.fixed,
      condition: {
        type: "",
        min: 1,
        max: 10
      }
    })

    // updated data source
    this.dsBilling.data = this.plan.billing;
  }

  public async removeBillingItem(item: ZenduOne.ProductPlanBilling) {
    let confirm = await this._dialog.open(CancelConfirmComponent, {
      width: '350px',
      data: {
        title: "Confirm",
        text: `Are you sure would like to delete the item "${item.name}" ?`
      }
    }).afterClosed().toPromise();
    if (!confirm) {
      return;
    }

    let idx = this.plan.billing.indexOf(item);
    this.plan.billing.splice(idx, 1);

    // update data source
    this.dsBilling.data = this.plan.billing;
  }


  public addFeature() {

    this.plan.features.push({
      name: "Feature",
      id: "",
      valueType: ""
    })

    // updated data source
    this.dsFeatures.data = this.plan.features;
  }

  public async removeFeature(feature: ZenduOne.ProductBillingFeature) {
    let confirm = await this._dialog.open(CancelConfirmComponent, {
      width: '350px',
      data: {
        title: "Confirm",
        text: `Are you sure would like to delete the feature "${feature.name}"?`
      }
    }).afterClosed().toPromise();
    if (!confirm) {
      return;
    }

    let idx = this.plan.features.indexOf(feature);
    this.plan.features.splice(idx, 1);

    // update data source
    this.dsFeatures.data = this.plan.features;
  }
}
