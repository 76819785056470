import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { ZenduOne } from 'src/typings/app';
import { debounceTime, switchMap } from 'rxjs/operators';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-customer-search',
  templateUrl: './customer-search.component.html',
  styleUrls: ['./customer-search.component.scss']
})
export class CustomerSearchComponent implements OnInit {

  public form: FormControl;

  public search: Observable<ZenduOne.Customer[]>;

  public isLoading: boolean;

  private _lastCustomer: ZenduOne.Customer;

  @Input() placeholder: string;

  @Input() allowEmpty: boolean;

  @Input() set customer(u: ZenduOne.Customer) {
    if (u) {
      // check changes
      if (!this.form.value ||
        u.name != this.form.value.name) {
        // update form control
        this._lastCustomer = u;
        this.form.setValue(u);
      }
    }
  };

  @Output() customerChange = new EventEmitter();

  constructor(
    private _customerService: CustomerService) {

    this.form = new FormControl();
    this.search = this.form.valueChanges
      .pipe(
        debounceTime(300),
        switchMap(searchName => this.searchUsers(searchName)),
      );
  }

  ngOnInit() {
  }

  public formChanged() {
    let customer = this.form.value as ZenduOne.Customer;
    if (!customer ||
      !customer.name) {

      if (this.allowEmpty &&
        !this.form.value &&
        this._lastCustomer) {
        // empty result
        this._lastCustomer = null;
        this.customerChange.emit(null);
      }

      return;
    }

    this._lastCustomer = customer;
    this.customerChange.emit(customer);
  }

  private async searchUsers(search: string) {
    try {
      if (!search) {
        return [];
      }

      this.isLoading = true;

      let users = await this._customerService.find({
        name: search ? `%${search}%` : ""
      });

      return users;
    }
    catch (err) {
      console.error(err);
      return [];
    }
    finally {
      this.isLoading = false;
    }
  }

  public showName(obj: Object) {
    if (!obj) {
      return "";
    }
    if (typeof obj == "string") {
      return obj;
    }
    let text = obj["name"];
    return text;
  }
}
