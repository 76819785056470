import { Component, OnInit, Inject, Optional } from '@angular/core';
import { ZenduOne } from 'src/typings/app';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NotifierService } from 'src/app/services/notifier.service';

@Component({
  selector: 'app-product-platform-edit',
  templateUrl: './product-platform-edit.component.html',
  styleUrls: ['./product-platform-edit.component.scss']
})
export class ProductPlatformEditComponent implements OnInit {

  public item: ZenduOne.ProductPlatform;

  public isLoading: boolean;

  constructor(
    @Optional() private _taskDialog: MatDialogRef<ZenduOne.ProductPlatform>,
    @Inject(MAT_DIALOG_DATA) public data: ZenduOne.ProductPlatform,
    private _notify: NotifierService
  ) {
    if (data) {
      this.item = data;
    }
    else {
      this.item = {
        id: "",
        database: "",
        required: false
      };
    }

  }

  ngOnInit() {
  }

  public save() {
    try {
      if (!this.item.id) {
        throw `Id is empty`
      }
      if (this.item.id == "geotab") {
        if (!this.item.addinCode) {
          throw "add-in code is empty"
        }
        try {
          JSON.parse(this.item.addinCode);
        } catch (err) {
          throw "add-in code is invalid"
        }
      }

      this._taskDialog.close(this.item);
    }
    catch (err) {
      this._notify.error(err);
    }
  }


}
