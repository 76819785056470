import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.scss']
})
export class AppMenuComponent implements OnInit {

  constructor(private _authService: AuthService) { }

  ngOnInit() {
  }

  public haveAccess(permisssion: string): boolean {
    let permisssions = this._authService.getPermissions()
    return permisssions.indexOf(permisssion) >= 0;
  }

  public isReseller() {
    return this._authService.isReseller();
  }

}
