import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { QueryService } from './services/query.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private _router: Router,
    private _queryService: QueryService
  ) {
  }

  ngOnInit() {

    if (document.location.pathname &&
      (document.location.pathname.indexOf("/management/") >= 0)) {

      let options = this._queryService.getOptions();
      options["backurl"] = document.location.pathname;

      this._router.navigate(["initalize"], { queryParams: options });
    }
  }
}
