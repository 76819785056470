import { Component, OnInit } from '@angular/core';
import { ZenduOne } from 'src/typings/app';
import { NotifierService } from 'src/app/services/notifier.service';
import { MatTableDataSource } from '@angular/material';
import { CategoryService } from 'src/app/services/category.service';

@Component({
  selector: 'app-categories-list',
  templateUrl: './categories-list.component.html',
  styleUrls: ['./categories-list.component.scss']
})
export class CategoriesListComponent implements OnInit {

  public categories: Array<ZenduOne.Category>;

  public isLoading: boolean;

  public displayedColumns: string[] = [
    "name"
  ];

  public dataSource = new MatTableDataSource();

  constructor(
    private _notify: NotifierService,
    private _categoryService: CategoryService
  ) { }

  ngOnInit() {
    this.init();
  }

  private async init() {
    try {
      this.isLoading = true;

      await this.update();
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  private async update() {
    try {
      this.isLoading = true;

      this.categories = await this._categoryService.find();

      this.dataSource.data = this.categories;
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }


}
