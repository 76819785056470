import { Component, OnInit } from '@angular/core';
import { ZenduOne } from 'src/typings/app';
import { NotifierService } from 'src/app/services/notifier.service';
import { ProductsService } from 'src/app/services/products.service';
import { MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss']
})
export class ProductsListComponent implements OnInit {

  public products: Array<ZenduOne.Product>;

  public isLoading: boolean;

  public displayedColumns: string[] = [
    "name",
    "installations",
    "status"
  ];

  public dataSource = new MatTableDataSource();

  constructor(
    private _notify: NotifierService,
    private _productsService: ProductsService
  ) { }

  ngOnInit() {
    this.init();
  }

  private async init() {
    try {
      this.isLoading = true;

      await this.update();
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  private async update() {
    try {
      this.isLoading = true;

      this.products = await this._productsService.find({});

      this.dataSource.data = this.products;
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

}
