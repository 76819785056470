import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/services/products.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { ResellerService } from 'src/app/services/reseller.service';
import { ZenduOne } from 'src/typings/app';
import { BillingService } from 'src/app/services/billing.service';

@Component({
  selector: 'app-customer-products',
  templateUrl: './customer-products.component.html',
  styleUrls: ['./customer-products.component.scss']
})
export class CustomerProductsComponent implements OnInit {

  public isLoading: boolean;

  public configuration: ZenduOne.ResellerConfiguration;

  public totalMargin: number;

  public productAccess: Array<{
    product: ZenduOne.Product,
    customerPrice: number,
    resellerCost: number,
    productPrice: number,
    plan: string,
    typeName: string,
    type: string,
    privewUrl: string,
    editable: boolean,
    hideBorder: boolean,
    enabled: boolean,
    appsInstalls: number
  }>;

  constructor(
    private _productService: ProductsService,
    private _resellerService: ResellerService,
    private _billingService: BillingService,
    private _notify: NotifierService) { }

  ngOnInit() {
    this.update();
  }

  private async update() {
    try {
      this.isLoading = true;

      this.configuration = await this._resellerService.findConfiguration();

      this.updateTotalMargin();

      const existAccess = await this._resellerService.findProductsAccess();
      const products = await this._productService.find({});
      const priceItemsAll = await this._billingService.calcResellerPricing({
        discount: this.configuration.discount,
        extraCharge: this.configuration.extraCharge
      });
      this.productAccess = [];
      for (let product of products) {
        const access = existAccess.find(e => e.productId == product._id);
        const enabled = access ? access.enabledForCustomer : false;

        const priceItems = priceItemsAll.filter(p => p.product == product.name);
        if (!priceItems.length) {
          this.productAccess.push({
            product: product,
            resellerCost: 0,
            customerPrice: 0,
            productPrice: 0,
            type: ``,
            typeName: ``,
            plan: "",
            privewUrl: "",
            enabled: enabled,
            hideBorder: false,
            editable: true,
            appsInstalls: 0
          });
          continue;
        }

        for (let priceItem of priceItems) {
          const isFirst = (priceItems[0] == priceItem);
          const isLast = (priceItems[priceItems.length - 1] == priceItem);

          this.productAccess.push({
            product: product,
            resellerCost: priceItem.resellerCost,
            customerPrice: priceItem.customerPrice,
            productPrice: priceItem.productPrice,
            typeName: priceItem.subItem.name,
            type: priceItem.subItem.type,
            plan: priceItem.plan,
            privewUrl: "",
            enabled: enabled,
            hideBorder: (isFirst || !isLast) && (priceItems.length >= 2),
            editable: isFirst,
            appsInstalls: priceItem.installs
          });
        }
      }

    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  public updateTotalMargin() {
    this.totalMargin = (this.configuration.discount + this.configuration.extraCharge);
  }

  public async save() {
    try {
      this.isLoading = true;

      // get selected products
      const productIds = this.productAccess
        .filter(p => p.enabled && p.editable)
        .map(p => p.product._id);
      // save ids
      await this._resellerService.updateProductsAccess(productIds);

      // update configuration
      await this._resellerService.updateConfiguration(this.configuration);

      // update information
      await this.update();
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  public toggleProductAccess() {
    let selCount = this.productAccess.filter(p => p.enabled).length;
    if (selCount > 0) {
      this.productAccess.map(p => p.enabled = false)
    }
    else {
      this.productAccess.map(p => p.enabled = true)
    }
  }

}
