import { Component, OnInit } from '@angular/core';
import { ZenduOne } from 'src/typings/app';
import { NotifierService } from 'src/app/services/notifier.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from 'src/app/services/customer.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { CancelConfirmComponent } from 'src/app/dialogs/cancel-confirm/cancel-confirm.component';
import { MatDialog } from '@angular/material';
import { VehicleType } from 'src/typings/vehiclesType';

@Component({
  selector: 'app-customer-vehicle-edit',
  templateUrl: './customer-vehicle-edit.component.html',
  styleUrls: ['./customer-vehicle-edit.component.scss']
})
export class CustomerVehicleEditComponent implements OnInit {

  public isLoading: boolean;

  public customer: ZenduOne.Customer;

  public vehicle: ZenduOne.Vehicle;

  public isNew: boolean;

  public vehiclesTypes: Array<{ id: string }>;

  constructor(
    private _notify: NotifierService,
    private _activeRoute: ActivatedRoute,
    private _customerService: CustomerService,
    private _vehicleService: VehicleService,
    private _dialog: MatDialog,
    private _router: Router
  ) {
    this.vehiclesTypes = [];
    for (let key of Object.keys(VehicleType)) {
      this.vehiclesTypes.push({ id: VehicleType[key] });
    }
  }

  ngOnInit() {
    this.update();
  }

  private async update() {
    try {
      this.isLoading = true;

      let query = this._activeRoute.snapshot.queryParams;
      if (!query.customerId) {
        throw "customerId is empty"
      }
      this.customer = await this._customerService.findOne(query.customerId);
      if (!this.customer) {
        throw `invalid customer id "${query.customerId}"`
      }

      if (query.id) {
        this.vehicle = await this._vehicleService.findOne(query.id);
        if (!this.vehicle) {
          throw `invalid vehicle id "${query.id}"`
        }
        this.isNew = false;
      }
      else {
        this.vehicle = this.getNewVehicle()
        this.isNew = true;
      }

      // prepare configuration
      //

      if (!this.vehicle.media) {
        this.vehicle.media = {
          serial: "",
          type: ""
        };
      }

      if (!this.vehicle.telematic) {
        this.vehicle.telematic = {
          serial: "",
          type: ""
        };
      }
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  private getNewVehicle(): ZenduOne.Vehicle {
    return {
      name: "",
      customerId: this.customer._id,
      media: {
        type: "",
        serial: ""
      },
      telematic: {
        type: "",
        serial: ""
      }
    };
  }

  public async save(next: boolean) {
    try {
      if (!this.vehicle.name.trim()) {
        throw `Name is empty`
      }
      if (!this.vehicle.telematic.type.trim() || !this.vehicle.telematic.serial.trim()) {
        throw `Telematic is empty`
      }

      this.isLoading = true;

      if (!this.vehicle._id) {
        let data = await this._vehicleService.add(this.vehicle);
        this.vehicle._id = data.id;

        this._notify.success("Vehicle created")

        if (next) {
          this.isNew = true;
          this.vehicle = this.getNewVehicle();
        }
      }
      else {
        await this._vehicleService.update(this.vehicle);

        this._notify.success("Vehicle updated")
      }
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }


  public async remove() {
    try {
      const dialogRef = this._dialog.open(CancelConfirmComponent, {
        width: '350px',
        data: {
          title: "Confirm",
          text: `Are you sure you want to remove vehicle "${this.vehicle.name}"?`
        }
      });
      let confirm = await dialogRef.afterClosed().toPromise();
      if (!confirm) {
        return;
      }

      this.isLoading = true;

      await this._vehicleService.remove(this.vehicle._id);

      this._router.navigate(["/management/customer/edit"], { queryParams: { id: this.customer._id } });
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

}
