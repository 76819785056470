import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { ZenduOne } from "src/typings/app";
import { Middleware } from "src/typings/middleware";

@Injectable()
export class ProductsService {
    constructor(private _httpService: HttpService) { }

    public async findOne(id: string): Promise<ZenduOne.Product> {
        return await this._httpService.post(`products/findOne`, {
            id: id
        });
    }

    public async find(search: {
        name?: string,
        ids?: Array<string>,
        category?: string
    }): Promise<Array<ZenduOne.Product>> {
        return await this._httpService.post(`products/find`, search);
    }

    public async update(product: ZenduOne.Product) {
        return await this._httpService.post(`products/update`, product);
    }

    public async add(product: ZenduOne.Product) {
        return await this._httpService.post(`products/add`, product);
    }

    public async install(options: {
        customerId: string,
        productId: string,
        users?: Middleware.ConfigureUser[],
        vehicles?: Middleware.ConfigureVehicle[],
        billingPlan?: string,
        optionalFeatures?: Array<string>,
        customParams?: Middleware.ConfigureCustomParameter[];
    }) {
        return await this._httpService.post(`installer/install`, options);
    }

    public async suspend(options: { customerId: string, productId: string }) {
        return await this._httpService.post(`installer/suspend`, options);
    }

    public async saveSSOSecret(options: { productType: string, key: string }) {
        await this._httpService.post(`products/sso/secret/update`, options);
    }
}