import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { ZenduOne } from "src/typings/app";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth.service";

@Injectable()
export class PlatformService {
    constructor(
        private _httpService: HttpService,
        private _auth: AuthService) { }

    public async findOne(search: {
        customerId: string,
        platformId: string
    }): Promise<{
        platform: ZenduOne.ProductPlatform,
        payload: Object
    }> {
        let item = await this._httpService.post("platforms/findOne", search);
        return item;
    }

    public async update(options: {
        customerId: string,
        platform: ZenduOne.ProductPlatform,
        payload: Object
    }) {
        await this._httpService.post("platforms/update", options);
    }

    public async add(options: {
        customerId: string,
        platform: ZenduOne.ProductPlatform,
        payload: Object,
        createNewDatabase: boolean
    }): Promise<{}> {
        return await this._httpService.post("platforms/add", options);
    }

    public async remove(options: {
        customerId: string,
        platformId: string
    }) {
        await this._httpService.post("platforms/remove", options);
    }

    public getLoginUrl(customer: ZenduOne.Customer) {
        if (!customer ||
            !customer.platforms ||
            !customer.platforms.length) {
            return "";
        }
        const platform = customer.platforms.find(p => p.id == "geotab");
        if (!platform) {
            return "";
        }

        return `${environment.service}/platforms/login?` +
            `token=${this._auth.getToken()}&customerId=${customer._id}&platformId=${platform.id}`;
    }

}