import { Component, OnInit, Optional, Inject } from '@angular/core';
import { ZenduOne } from 'src/typings/app';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotifierService } from 'src/app/services/notifier.service';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-customer-app-select',
  templateUrl: './customer-app-select.component.html',
  styleUrls: ['./customer-app-select.component.scss']
})
export class CustomerAppSelectComponent implements OnInit {

  public isLoading: boolean;

  public selectedItem: ZenduOne.Product;

  public items: Array<ZenduOne.Product>;

  public isInstalled: boolean;

  private _ignoreList: Array<string>;

  constructor(
    @Optional() private _taskDialog: MatDialogRef<ZenduOne.Product>,
    @Inject(MAT_DIALOG_DATA) public data: {
      ignore: Array<string>
    },
    private _notify: NotifierService,
    private _productService: ProductsService
  ) {
    this._ignoreList = data ? data.ignore : null;
  }

  ngOnInit() {
    this.init();
  }

  private async init() {
    try {
      this.isLoading = true;

      this.items = await this._productService.find({});
      if (this._ignoreList &&
        this._ignoreList.length) {
        this.items = this.items.filter(i => this._ignoreList.indexOf(i._id) < 0);
      }
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  public accept() {
    try {
      if (!this.selectedItem) {
        throw `App is not selected`
      }

      this._taskDialog.close(this.selectedItem);
    }
    catch (err) {
      this._notify.error(err);
    }
  }

}
