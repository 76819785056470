import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { ImageUploadModule } from "angular2-image-upload";
import { ChartModule } from 'angular-highcharts';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { AppMaterialModule } from './app.material';

import { QueryService } from './services/query.service';
import { NotifierService } from './services/notifier.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { HtmlTooltip } from './components/tooltip/htmlTooltip';
import { Daterangepicker } from 'ng2-daterangepicker';
import { HttpService } from './services/http.service';
import { ColorPickerModule } from 'ngx-color-picker';
import { HtmlTooltipContent } from './components/tooltip/htmlTooltipContent';
import { AppService } from './services/app.service';
import { MatGridListModule, MatListModule, MatToolbarModule, MatTabsModule, MatPaginatorModule } from '@angular/material';
import { AppNavbarComponent } from './components/app-navbar/app-navbar.component';
import { ProductsService } from './services/products.service';
import { CarouselComponent } from './components/carousel/carousel.component';
import { SafePipe } from './pipes/safe.pipe';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { AuthService } from './services/auth.service';
import { RegisterComponent } from './pages/register/register.component';
import { FooterComponent } from './components/footer/footer.component';
import { InitalizeComponent } from './pages/initalize/initalize.component';
import { AuthGuard } from './guards/auth.guard';
import { AppMenuComponent } from './components/app-menu/app-menu.component';
import { ProductsListComponent } from './pages/products/products-list/products-list.component';
import { ProductEditComponent } from './pages/products/product-edit/product-edit.component';
import { PreviewEditComponent } from './dialogs/preview-edit/preview-edit.component';
import { CancelConfirmComponent } from './dialogs/cancel-confirm/cancel-confirm.component';
import { ProductAttachmentsComponent } from './pages/products/components/product-attachments/product-attachments.component';
import { CategoriesListComponent } from './pages/categories/categories-list/categories-list.component';
import { DashboardOverviewComponent } from './pages/dashboard/dashboard-overview/dashboard-overview.component';
import { DefaultContainerComponent } from './pages/default-container/default-container.component';
import { ResellersListComponent } from './pages/resellers/resellers-list/resellers-list.component';
import { ResellersEditComponent } from './pages/resellers/resellers-edit/resellers-edit.component';
import { VechiclesListComponent } from './pages/vehicles/vechicles-list/vechicles-list.component';
import { UsersEditComponent } from './pages/users/users-edit/users-edit.component';
import { CustomerService } from './services/customer.service';
import { CustomersListComponent } from './pages/customers/customers-list/customers-list.component';
import { CustomersAppsListComponent } from './pages/customers/components/customer-apps-list/customer-apps-list.component';
import { UserService } from './services/user.service';
import { RoleService } from './services/role.service';
import { UsersListComponent } from './pages/users/users-list/users-list.component';
import { CategoryService } from './services/category.service';
import { ProductFeatureEditComponent } from './pages/products/components/product-feature-edit/product-feature-edit.component';
import { ProductPlatformsComponent } from './pages/products/components/product-platforms/product-platforms.component';
import { ProductPlatformEditComponent } from './pages/products/components/product-platform-edit/product-platform-edit.component';
import { ProductPlanEditComponent } from './pages/products/components/product-plan-edit/product-plan-edit.component';
import { ProductAttachmentEditComponent } from './pages/products/components/product-attachment-edit/product-attachment-edit.component';
import { CustomerVechilesListComponent } from './pages/customers/components/customer-vechiles-list/customer-vechiles-list.component';
import { CustomersEditComponent } from './pages/customers/customer-edit/customer-edit.component';
import { CustomerPlatformsListComponent } from './pages/customers/components/customer-platforms-list/customer-platforms-list.component';
import { CustomerPlatformEditComponent } from './pages/customers/components/customer-platform-edit/customer-platform-edit.component';
import { CustomerProductInstallComponent } from './pages/customers/components/customer-product-install/customer-product-install.component';
import { CustomerVehicleEditComponent } from './pages/customers/components/customer-vehicle-edit/customer-vehicle-edit.component';
import { ProductPlansComponent } from './pages/products/components/product-plans/product-plans.component';
import { ProductFeaturesComponent } from './pages/products/components/product-features/product-features.component';
import { CategoriesEditComponent } from './pages/categories/categories-edit/categories-edit.component';
import { VehicleService } from './services/vehicle.service';
import { PlatformService } from './services/platform.service';
import { CustomerAppSelectComponent } from './pages/customers/components/customer-app-select/customer-app-select.component';
import { ProductInstallationComponent } from './pages/products/components/product-installation/product-installation.component';
import { CustomerBillingComponent } from './pages/customers/components/customer-billing/customer-billing.component';
import { BillingLabelPipe } from './pipes/billing.label.pipe';
import { VechileTypeLabelPipe } from './pipes/vehicle.type.label.pipe';
import { BillingService } from './services/billing.service';
import { AuditListComponent } from './pages/audit/audit-list/audit-list.component';
import { AuditService } from './services/audit.service';
import { CustomerCustomBillingComponent } from './pages/customers/components/customer-custom-billing/customer-custom-billing.component';
import { ResellerService } from './services/reseller.service';
import { CustomerSearchComponent } from './pages/customers/components/customer-search/customer-search.component';
import { ResellerSettingsComponent } from './pages/reseller-settings/reseller-settings.component';
import { ClipboardService } from './services/clipboard.service';
import { StripeOauthComponent } from './pages/stripe-oauth/stripe-oauth.component';
import { AgreementComponent } from './pages/agreement/agreement.component';
import { StripeAccountConnectComponent } from './pages/stripe-account-connect/stripe-account-connect.component';
import { CustomerProductsComponent } from './pages/customer-products/customer-products.component';
import { DashboardService } from './services/dashboard.service';
import { BillingComponent } from './pages/billing/billing.component';
import { CountryService } from './services/country.service';
import { RegistrationFormComponent } from './pages/registration/registration-form/registration-form.component';
import { DashboardStartupComponent } from './pages/dashboard/dashboard-startup/dashboard-startup.component';
import { ZohoProfileComponent } from './pages/zoho/zoho-profile/zoho-profile.component';
import { ClickStopDirective } from './directives/click-stop-propagation';
import { OutsideClickDirective } from './directives/outside-click';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        AppRoutingModule,
        AppMaterialModule,
        FlexLayoutModule,
        NgMultiSelectDropDownModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        Daterangepicker,
        ImageUploadModule.forRoot(),
        DragDropModule,
        MatGridListModule,
        MatListModule,
        MatToolbarModule,
        MatTabsModule,
        ChartModule,
        MatPaginatorModule,
        ColorPickerModule
    ],
    declarations: [
        AppComponent,
        HtmlTooltip,
        HtmlTooltipContent,
        AppNavbarComponent,
        CarouselComponent,
        SafePipe,
        BillingLabelPipe,
        VechileTypeLabelPipe,
        SignInComponent,
        RegisterComponent,
        FooterComponent,
        InitalizeComponent,
        AppMenuComponent,
        ProductsListComponent,
        ProductEditComponent,
        PreviewEditComponent,
        CancelConfirmComponent,
        ProductPlansComponent,
        ProductFeaturesComponent,
        ProductAttachmentsComponent,
        CategoriesListComponent,
        CategoriesEditComponent,
        DashboardOverviewComponent,
        DefaultContainerComponent,
        ResellersListComponent,
        ResellersEditComponent,
        VechiclesListComponent,
        UsersListComponent,
        UsersEditComponent,
        CustomersEditComponent,
        CustomersListComponent,
        CustomersAppsListComponent,
        ProductFeatureEditComponent,
        ProductPlatformsComponent,
        ProductPlatformEditComponent,
        ProductPlanEditComponent,
        ProductAttachmentEditComponent,
        CustomerVechilesListComponent,
        CustomerPlatformsListComponent,
        CustomerPlatformEditComponent,
        CustomerProductInstallComponent,
        CustomerVehicleEditComponent,
        CustomerAppSelectComponent,
        ProductInstallationComponent,
        CustomerBillingComponent,
        AuditListComponent,
        CustomerCustomBillingComponent,
        CustomerSearchComponent,
        ResellerSettingsComponent,
        StripeOauthComponent,
        AgreementComponent,
        StripeAccountConnectComponent,
        CustomerProductsComponent,
        BillingComponent,
        RegistrationFormComponent,
        DashboardStartupComponent,
        ZohoProfileComponent,
        ClickStopDirective,
        OutsideClickDirective
    ],
    providers: [
        QueryService,
        NotifierService,
        ProductsService,
        CategoryService,
        CookieService,
        HttpService,
        AppService,
        AuthService,
        CustomerService,
        UserService,
        VehicleService,
        RoleService,
        PlatformService,
        AuthGuard,
        BillingService,
        AuditService,
        ResellerService,
        ClipboardService,
        DashboardService,
        CountryService
    ],
    entryComponents: [
        PreviewEditComponent,
        CancelConfirmComponent,
        ProductFeatureEditComponent,
        ProductPlatformEditComponent,
        ProductPlanEditComponent,
        ProductAttachmentEditComponent,
        CustomerAppSelectComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}