import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { NotifierService } from 'src/app/services/notifier.service';
import { ZenduOne } from 'src/typings/app';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { ResellerService } from 'src/app/services/reseller.service';
import { CustomerService } from 'src/app/services/customer.service';
import { UserRoleName } from 'src/typings/userRoleName';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {

  @ViewChild("paginator_resellers") paginatorResellers: MatPaginator;

  @ViewChild("paginator_customers") paginatorCustomers: MatPaginator;

  @ViewChild("paginator_admins") paginatorAdmins: MatPaginator;

  @ViewChild("paginator_api") paginatorApis: MatPaginator;

  public isLoading: boolean;

  public dsAdmins = new MatTableDataSource();

  public dsCustomers = new MatTableDataSource();

  public dsResellers = new MatTableDataSource();

  public dsApis = new MatTableDataSource();

  public isLoadingCustomers = false;

  public isLoadingAdmins = false;

  public isLoadingResellers = false;

  /**
   * Loading indicator for api users
   */
  public isLoadingAPI = false;

  /**
   * admin search filter
   */
  public adminSearch = {
    name: ""
  }

  /**
   * reseller search filter
   */
  public resellerSearch = {
    name: ""
  }

  /**
   * customer search options
   */
  public customerSearch = {
    name: ""
  }


  /**
   * api accounts search options
   */
  public apiSearch = {
    name: ""
  }



  private _resellers: ZenduOne.Reseller[];

  constructor(
    private _userService: UserService,
    private _notify: NotifierService,
    private _authService: AuthService,
    private _resellerService: ResellerService,
    private _customerService: CustomerService) {
  }

  ngOnInit() {
    this.init();
  }

  private async init() {
    try {
      this.isLoading = true;

      const resellersCount = await this._userService.count({ roleIds: [UserRoleName.reseller] });
      const customersCount = await this._userService.count({ roleIds: [UserRoleName.customer] });
      const adminsCount = await this._userService.count({ roleIds: [UserRoleName.admin, UserRoleName.sales, UserRoleName.adminViewer] });
      const apiCount = await this._userService.count({ roleIds: [UserRoleName.service] });

      this._resellers = await this._resellerService.find();

      setTimeout(() => {
        //
        // run first update with timeout after paginator initalization
        //

        // setup paginators
        this.paginatorCustomers.length = customersCount;
        this.loadCustomers();
        this.paginatorCustomers.page.subscribe(() => {
          this.loadCustomers();
        })

        this.paginatorResellers.length = resellersCount;
        this.loadResellers();
        // subscribe to paginator changes
        this.paginatorResellers.page.subscribe(() => {
          this.loadResellers();
        })

        if (this.paginatorAdmins) {
          this.paginatorAdmins.length = adminsCount;
          this.loadAdmins();

          // subscribe to paginator changes
          this.paginatorAdmins.page.subscribe(() => {
            this.loadAdmins();
          })
        }

        if (this.paginatorApis) {
          this.paginatorApis.length = apiCount;
          this.loadAPIs();

          // subscribe to paginator changes
          this.paginatorApis.page.subscribe(() => {
            this.loadAPIs();
          })
        }

      }, 100);
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }


  /**
   * load customers for current page
   */
  public async loadCustomers() {
    try {
      this.isLoadingCustomers = true;
      this.dsCustomers.data = [];

      // load users
      const users = await this._userService.find({
        offset: (this.paginatorCustomers.pageIndex * this.paginatorCustomers.pageSize) || 0,
        limit: this.paginatorCustomers.pageSize,
        name: this.customerSearch.name ? `%${this.customerSearch.name}%` : null,
        roleIds: ["customer.user"]
      });
      // get customers ids
      const customersIds = users
        .filter(u => u.customerId)
        .map(u => u.customerId)
        .filter((v, idx, arr) => arr.indexOf(v) == idx);
      // load customers
      const customers = await this._customerService.find({ ids: customersIds });

      // build view items
      //
      const viewItems = [];
      for (const user of users) {
        let resellerName = "-";
        let customerName = "-";
        const customer = customers.find(c => c._id == user.customerId);
        if (customer) {
          customerName = customer.name;
          const reseller = this._resellers.find(r => r._id == customer.resellerId);
          if (reseller) {
            resellerName = reseller.name;
          }
        }

        viewItems.push({
          _id: user._id,
          name: user.name,
          reseller: resellerName,
          customer: customerName,
          roles: this.getRoles(user)
        });
      }

      this.dsCustomers.data = viewItems;
    }
    finally {
      this.isLoadingCustomers = false;
    }
  }

  /**
   * load admins users for current page
   */
  private async loadAdmins() {
    try {
      this.isLoadingAdmins = true;
      this.dsAdmins.data = [];

      const users = await this._userService.find({
        offset: (this.paginatorAdmins.pageIndex * this.paginatorAdmins.pageSize) || 0,
        limit: this.paginatorAdmins.pageSize,
        name: this.adminSearch.name ? `%${this.adminSearch.name}%` : "",
        roleIds: [UserRoleName.admin, UserRoleName.sales, UserRoleName.adminViewer]
      })

      this.dsAdmins.data = users;
    }
    finally {
      this.isLoadingAdmins = false;
    }
  }

  /**
  * load admins users for current page
  */
  private async loadAPIs() {
    try {
      this.isLoadingAPI = true;
      this.dsAdmins.data = [];

      const users = await this._userService.find({
        offset: (this.paginatorApis.pageIndex * this.paginatorApis.pageSize) || 0,
        limit: this.paginatorApis.pageSize,
        name: this.apiSearch.name ? `%${this.apiSearch.name}%` : "",
        roleIds: [UserRoleName.service, UserRoleName.partnerApi]
      })

      this.dsApis.data = users;
    }
    finally {
      this.isLoadingAPI = false;
    }
  }

  /**
   * load resellers users for given page
   */
  private async loadResellers() {
    try {
      this.isLoadingResellers = true;

      this.dsResellers.data = [];

      const users = await this._userService.find({
        offset: (this.paginatorResellers.pageIndex * this.paginatorResellers.pageSize) || 0,
        limit: this.paginatorResellers.pageSize,
        name: this.resellerSearch.name ? `%${this.resellerSearch.name}%` : "",
        roleIds: [UserRoleName.reseller]
      })
      this.dsResellers.data = [];
      const viewItems = [];
      for (const user of users) {
        const reseller = this._resellers.find(r => r._id == user.resellerId);
        viewItems.push({
          _id: user._id,
          name: user.name,
          reseller: reseller ? reseller.name : "-",
          roles: this.getRoles(user)
        });
      }

      this.dsResellers.data = viewItems;
      this.dsResellers.paginator = this.paginatorResellers;
    }
    finally {
      this.isLoadingResellers = false;
    }
  }

  public getRoles(user: ZenduOne.User) {
    if (!user || !user.roles) {
      return "-"
    }
    return user.roles.join(",");
  }

  public haveAccess(permisssion: string): boolean {
    let permisssions = this._authService.getPermissions()
    return permisssions.indexOf(permisssion) >= 0;
  }

  public applyFilter(dataSource: MatTableDataSource<any>, event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    dataSource.filter = filterValue.trim().toLowerCase();
  }
}
