import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'src/app/services/notifier.service';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.scss']
})
export class AgreementComponent implements OnInit {

  public isLoading: boolean;

  constructor(
    private _notify: NotifierService,
    private _userService: UserService,
    private _authService: AuthService,
    private _router: Router) { }

  ngOnInit() {
  }

  public async accept() {
    try {
      // accept agreement
      await this._userService.acceptAgreement();

      if (this._authService.isStripeConnectRequired()) {
        // ask reseller connect to stripe
        this._router.navigate(["/stripe-account-connect"]);
        return;
      }

      this._router.navigate(["/managment/dashboard"])
    }
    catch (err) {
      this._notify.error(err);
    }
  }

  public async cancel() {
    this._router.navigate(["/sign-in"])
  }
}
