import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResellerService } from 'src/app/services/reseller.service';
import { NotifierService } from 'src/app/services/notifier.service';

@Component({
  selector: 'app-stripe-oauth',
  templateUrl: './stripe-oauth.component.html',
  styleUrls: ['./stripe-oauth.component.scss']
})
export class StripeOauthComponent implements OnInit {

  public isLoading: boolean;

  public errorText: string;

  public successText: string;

  constructor(
    private _activeRoute: ActivatedRoute,
    private _resellerService: ResellerService,
    private _router: Router,
    private _notify: NotifierService) {
  }

  ngOnInit() {
    this.init();
  }

  private async init() {
    try {
      this.isLoading = true;

      let query = this._activeRoute.snapshot.queryParams;

      let error = query.error;
      if (error) {
        // If the authorization was denied by the user, they'll still be redirected back to your site,
        // but the URL includes an error instead of the authorization code
        this.errorText = query.error_description || error;
        return;
      }

      let scope = query.scope;
      if (!scope) {
        throw "scope not passed"
      }
      let code = query.code;
      if (!code) {
        throw "code not passed"
      }

      await this._resellerService.connect({ code: code });

      this.successText = `You successully connected to platform.`

      setTimeout(() => {
        // hard reload to root page
        document.location.href = "/";
      }, 5000)
    }
    catch (err) {
      this.errorText = this._notify.getErrorMessage(err);
    }
    finally {
      this.isLoading = false;
    }
  }

}
