import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-stripe-account-connect',
  templateUrl: './stripe-account-connect.component.html',
  styleUrls: ['./stripe-account-connect.component.scss']
})
export class StripeAccountConnectComponent implements OnInit {

  public clientId: string;

  constructor() {
    this.clientId = environment.stripeClientId;
   }

  ngOnInit() {
  }

}
