import { Component, Input, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { ZenduOne } from 'src/typings/app';
import { NotifierService } from 'src/app/services/notifier.service';
import { BillingService } from 'src/app/services/billing.service';
import { RouteBillingCustomer } from 'src/typings/billing';
import { AuthService } from 'src/app/services/auth.service';
import { UserPermissions } from 'src/typings/userPermissions';

@Component({
  selector: 'app-customer-billing',
  templateUrl: './customer-billing.component.html',
  styleUrls: ['./customer-billing.component.scss']
})
export class CustomerBillingComponent implements OnChanges {

  @Input() billing: RouteBillingCustomer;

  public isLoading: boolean;

  public dsBilling = new MatTableDataSource();

  public isEditable: boolean;

  constructor(
    private _billingService: BillingService,
    private _notify: NotifierService,
    private _authService: AuthService) {

    this.isEditable =
      this._authService.getPermissions().indexOf(UserPermissions.management.write) >= 0;
  }

  ngOnChanges() {
    this.update();
  }

  public async update() {
    try {
      this.isLoading = true;

      if (!this.billing) {
        return;
      }

      if (!this.billing ||
        !this.billing.products.length) {
        return;
      }

      let viewItems = [];
      for (let productBilling of this.billing.products) {

        for (let feature of productBilling.billings) {
          viewItems.push({
            productName: productBilling.name,
            item: feature
          })
        }
      }

      this.dsBilling.data = viewItems;
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }

  }

  public async save() {
    try {
      this.isLoading = true;

      if (!this.billing) {
        return;
      }

      await this._billingService.updateProducts(this.billing);
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

}
