import { Component, OnInit, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { NotifierService } from 'src/app/services/notifier.service';
import { VehicleService } from 'src/app/services/vehicle.service';

@Component({
  selector: 'app-vechicles-list',
  templateUrl: './vechicles-list.component.html',
  styleUrls: ['./vechicles-list.component.scss']
})
export class VechiclesListComponent implements OnInit {

  public isLoading: boolean;

  public dataSource = new MatTableDataSource();

  constructor(
    private _notify: NotifierService,
    private _vehicleService: VehicleService
  ) {
  }

  ngOnInit() {
    this.init();
  }

  private async init() {
    try {
      this.isLoading = true;

      await this.update();
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  private async update() {
    try {
      this.isLoading = true;

      let items = await this._vehicleService.find({ });
      this.dataSource.data = items;
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

}
