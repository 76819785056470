import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { RouteBillingCalculateParam, BillingCalculateResult, InstallationBilling } from "src/typings/billing";
import { RouteBillingCustomer, BillingResellerView, BillingInvoiceResult } from "src/typings/billing";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth.service";


@Injectable()
export class BillingService {
    constructor(
        private _auth: AuthService,
        private _httpService: HttpService) { }

    /**
     * Calculate pricing for application based on installation options
     */
    public async calculateApp(options: RouteBillingCalculateParam): Promise<BillingCalculateResult> {
        let data = await this._httpService.post("billing/calculate", options);
        return data;
    }

    public async findCustomerBilling(options: {
        customerId: string
    }): Promise<RouteBillingCustomer> {
        let data = await this._httpService.post("billing/customer/findOne", options);
        return data;
    }

    public async findCustomerBillings(options: {
        customerIds: string[]
    }): Promise<RouteBillingCustomer[]> {
        let data = await this._httpService.post("billing/customer/find", options);
        return data;
    }

    /**
     * Find total/reseller cost in the Stripe for given customer(s)
     */
    public async findCustomerTotal(options: {
        customerIds: string[]
    }): Promise<BillingInvoiceResult[]> {
        let data = await this._httpService.post("billing/customer/total", options);
        return data;
    }

    /**
     * Calculate pricing for products based on reseller discount and extra markup
     */
    public async calcResellerPricing(options: {
        discount: number,
        extraCharge: number
    }): Promise<BillingResellerView[]> {
        let data = await this._httpService.post("billing/reseller/calculate", options);
        return data;
    }

    public async updateProducts(options: RouteBillingCustomer) {
        let data = await this._httpService.post("billing/product/update", options);
        return data;
    }

    /**
     * update custom billings for given customer
     */
    public async updateCustom(options: RouteBillingCustomer) {
        let data = await this._httpService.post("billing/custom/update", options);
        return data;
    }

    public async installationsCount(options: {
        resellerIds?: string[],
        customerIds?: string[]
    }): Promise<number> {
        let data = await this._httpService.post("billing/installations/count", options);
        return data.count;
    }

    public async findInstallations(options: {
        resellerIds?: string[],
        customerIds?: string[],
        offset: number,
        limit: number
    }): Promise<InstallationBilling[]> {
        let data = await this._httpService.post("billing/installations/list", options);
        return data;
    }

    public installationsExport(options: {
        resellerIds: string[],
        customerIds: string[]
    }) {
        const resellerIds = options.resellerIds ? options.resellerIds.join(",") : "";
        const customerIds = options.customerIds ? options.customerIds.join(",") : "";
        const token = this._auth.getToken();
        window.open(`${environment.service}/billing/installations/download?resellerIds=${resellerIds}` +
            `&customerIds=${customerIds}` +
            `&token=${token}`, "_blank");
    }

}