import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ZenduOne } from 'src/typings/app';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { CancelConfirmComponent } from 'src/app/dialogs/cancel-confirm/cancel-confirm.component';
import { ProductAttachmentEditComponent } from '../product-attachment-edit/product-attachment-edit.component';


@Component({
  selector: 'app-product-attachments',
  templateUrl: './product-attachments.component.html',
  styleUrls: ['./product-attachments.component.scss']
})
export class ProductAttachmentsComponent implements OnInit {

  public isLoading: boolean;

  public displayedColumns: string[] = [
    "name",
    "url",
    "remove"
  ];

  @Input() product: ZenduOne.Product;

  public dataSource = new MatTableDataSource();

  constructor(private _dialog: MatDialog) { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.product) {
      this.setProduct(this.product);
    }
  }

  private setProduct(product: ZenduOne.Product) {
    this.dataSource.data = product.content.attachments ? product.content.attachments : [];
  }

  public async createNew() {
   // open edit dialog
   let result = await this._dialog.open(ProductAttachmentEditComponent,
    { data: null })
    .afterClosed().toPromise();

  // handle dialog result
  if (result) {
    // add a new item
    if (!this.product.content.attachments) {
      this.product.content.attachments = [];
    }
    this.product.content.attachments.push(result);
    this.dataSource.data = this.product.content.attachments;
  }
  }


  public async edit(plan: ZenduOne.ProductAttachment) {
    // open edit dialog
    await this._dialog.open(ProductAttachmentEditComponent,
      { data: plan })
      .afterClosed().toPromise();
  }

  public async remove(attachment: ZenduOne.ProductAttachment) {
    let confirm = await this._dialog.open(CancelConfirmComponent, {
      width: '350px',
      data: {
        title: "Confirm",
        text: `Are you sure would like to delete "${attachment.name}"?`
      }
    }).afterClosed().toPromise();
    if (!confirm) {
      return;
    }

    let idx = this.product.content.attachments.indexOf(attachment)
    this.product.content.attachments.splice(idx, 1);
    this.dataSource.data = this.product.content.attachments;
  }
}
