import { Component, OnInit } from '@angular/core';
import { ZenduOne } from 'src/typings/app';
import { CountryService } from 'src/app/services/country.service';
import { ResellerService } from 'src/app/services/reseller.service';
import { NotifierService } from 'src/app/services/notifier.service';

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss']
})
export class RegistrationFormComponent implements OnInit {

  public reseller: ZenduOne.Reseller;

  public countries: Array<{ id: string, name: string }>

  public isLoading: boolean;

  public isCompleted: boolean;

  constructor(
    private _resellerService: ResellerService,
    private _notify: NotifierService,
    private _countryService: CountryService) {
    this.reseller = { address: { country: "USA" } } as ZenduOne.Reseller;
    this.countries = this._countryService.getList();
  }

  ngOnInit() {
  }

  public async confirm() {
    try {

      this.validate();

      this.isLoading = true;

      await this._resellerService.completeRegistration(this.reseller);
      this.isCompleted = true;
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  private validate(){
    if (!this.reseller.name)
    {
        throw "Company name is empty"
    }
    if (!this.reseller.email)
    {
        throw "Email is empty"
    }
    if (!this.reseller.phone)
    {
        throw "Phone number is empty"
    }
    if (!this.reseller.billingEmail)
    {
        throw "Billing email is empty"
    }
    if (!this.reseller.address.street)
    {
        throw "Billing street is empty"
    }
    if (!this.reseller.address.city)
    {
        throw "Billing city is empty"
    }
  }
}
