import { NotifierService } from 'src/app/services/notifier.service';
import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-initalize',
  templateUrl: './initalize.component.html',
  styleUrls: ['./initalize.component.scss']
})
export class InitalizeComponent implements OnInit {

  constructor(
    private _router: Router,
    private _activeRouter: ActivatedRoute,
    private _notifier: NotifierService,
    private _authService: AuthService) { }

  ngOnInit() {
    this.init();
  }

  private async init() {
    try {
      let res = await this._authService.initAuth();
      if (!res) {
        this._router.navigate(['sign-in']);
        return;
      }

      if (this._authService.isReseller()) {
        if (!this._authService.isAgreementAccepted()) {
          // user for given reseller is not accepted the agreement
          // redirect user to agreemnt page
          this._router.navigate(['agreement']);
          return;
        }
      }

      let options = this._activeRouter.snapshot.queryParams;
      let backurl = options.backurl;
      if (backurl) {
        // clone properties to remove backurl argument and empty argument
        let copyOptions = Object.assign({}, options);
        delete copyOptions["backurl"];
        delete copyOptions[""];
        // navigate to backurl page
        this._router.navigate([backurl], { queryParams: copyOptions });
      }
      else {
        // navigate to default page
        this._router.navigate(['/management/dashboard/overview']);
      }
    }
    catch (err) {
      this._notifier.error(err);
      this._router.navigate(['sign-in']);
    }
  }

}
