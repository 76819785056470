import { Component, OnInit, Input } from '@angular/core';
import { ZenduOne } from 'src/typings/app';
import { MatTableDataSource, MatDialog } from '@angular/material';

@Component({
  selector: 'app-product-plans',
  templateUrl: './product-plans.component.html',
  styleUrls: ['./product-plans.component.scss']
})
export class ProductPlansComponent implements OnInit {

  public isLoading: boolean;

  public displayedColumns: string[] = [
    "name",
    "description"
  ];

  @Input() product: ZenduOne.Product;

  public dataSource = new MatTableDataSource();

  constructor() { }

  ngOnInit() {
  }

  public ngOnChanges() {
    if (this.product) {
      this.dataSource.data = this.product.plans;
    }
  }


}
