import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { ZenduOne } from 'src/typings/app';
import { MatTableDataSource, MatDialog, MatPaginator } from '@angular/material';
import { NotifierService } from 'src/app/services/notifier.service';
import { VehicleService } from 'src/app/services/vehicle.service';

@Component({
  selector: 'app-customer-vechiles-list',
  templateUrl: './customer-vechiles-list.component.html',
  styleUrls: ['./customer-vechiles-list.component.scss']
})
export class CustomerVechilesListComponent implements OnChanges {

  @Input() customer: ZenduOne.Customer;

  @ViewChild("paginator") paginator: MatPaginator;

  public isLoading: boolean;

  public dataSource = new MatTableDataSource();

  public filter: {
    name?: string;
    telematicSerial?: string;
    mediaSerial?: string;
  } = {};

  private _vehicles: ZenduOne.Vehicle[];

  private _searchTimer;

  constructor(
    private _dialog: MatDialog,
    private _notify: NotifierService,
    private _vehicleService: VehicleService
  ) { }


  ngOnChanges() {
    this.update();
  }

  private async update() {
    try {
      this.isLoading = true;
      if (this.customer) {
        this._vehicles = await this._vehicleService.find({ customerId: this.customer._id });
      }

      this.updateDataSource();
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }

  }

  private updateDataSource() {
    if (!this._vehicles) {
      this.dataSource.data = [];
      return;
    }

    let target = this._vehicles;
    if (this.filter.name) {
      // filter by name
      const filterVal = this.filter.name.toLocaleLowerCase();
      target = target.filter(t =>
        t.name && t.name.toLowerCase().indexOf(filterVal) >= 0);
    }
    if (this.filter.telematicSerial) {
      // filter by telematic serial
      const filterVal = this.filter.telematicSerial.toLocaleLowerCase();
      target = target.filter(t =>
        t.telematic && t.telematic.serial && t.telematic.serial.toLowerCase().indexOf(filterVal) >= 0);
    }
    if (this.filter.mediaSerial) {
      // filter by media serial
      const filterVal = this.filter.mediaSerial.toLocaleLowerCase();
      target = target.filter(t =>
        t.media && t.media.serial && t.media.serial.toLowerCase().indexOf(filterVal) >= 0);
    }

    this.dataSource.data = target;

    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
    }, 100);
  }

  public search() {
    clearTimeout(this._searchTimer);
    this._searchTimer = setTimeout(() => {
      this.paginator.firstPage();
      this.updateDataSource();
    }, 1000);
  }

}
