import { Component, OnInit, ViewChild } from '@angular/core';
import { NotifierService } from 'src/app/services/notifier.service';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { CustomerService } from 'src/app/services/customer.service';
import { ResellerService } from 'src/app/services/reseller.service';
import { ZenduOne } from 'src/typings/app';
import { AuthService } from 'src/app/services/auth.service';
import { BillingService } from 'src/app/services/billing.service';
import { PlatformService } from 'src/app/services/platform.service';

@Component({
  selector: 'app-customers-list',
  templateUrl: './customers-list.component.html',
  styleUrls: ['./customers-list.component.scss']
})
export class CustomersListComponent implements OnInit {

  @ViewChild("paginator") paginator: MatPaginator;

  public isLoading: boolean;

  public filter: {
    name?: string,
    reseller?: string,
    email?: string,
    database?: string,
    zohoAccount?: string
  } = {};

  public items = [];

  public columns = [];

  public resellers: ZenduOne.Reseller[] = [];

  private _searchTimer;

  constructor(
    private _notify: NotifierService,
    private _customerService: CustomerService,
    private _resellerService: ResellerService,
    private _authService: AuthService,
    private _billingService: BillingService,
    private _platformService: PlatformService
  ) {
    if (this._authService.isReseller()) {
      this.columns = ["name", "email", "database", "installs", "reseller_cost", "cusomter_total"];
    }
    else {
      this.columns = ["name", "email", "reseller", "database", "zoho", "installs", "reseller_cost", "cusomter_total"];
    }
  }

  ngOnInit() {
    this.init();
  }

  private async init() {
    try {
      this.isLoading = true;

      // load resellers
      this.resellers = await this._resellerService.find();

      // load total number of customers
      this.paginator.length = await this._customerService.count();

      setTimeout(() => {
        // run first update with timeout after paginator initalization
        this.update();

        // subscribe to paginator changes
        this.paginator.page.subscribe(() => {
          this.update();
        })
      }, 100);
    }
    catch (err) {
      this._notify.error(err);
    }
  }

  private async update() {
    try {
      this.isLoading = true;


      let platform = null;
      if (this.filter.database) {
        platform = { database: `%${this.filter.database}%` }
      }

      let customers = await this._customerService.find({
        name: this.filter.name ? `%${this.filter.name}%` : "",
        resellerId: this.filter.reseller,
        email: this.filter.email ? `%${this.filter.email}%` : "",
        platform: platform,
        zohoAccountId: this.filter.zohoAccount ? `%${this.filter.zohoAccount}%` : "",
        offset: (this.paginator.pageIndex * this.paginator.pageSize) || 0,
        limit: this.paginator.pageSize
      });

      this.items = [];
      for (let customer of customers) {
        const reseller = this.resellers.find(r => r._id == customer.resellerId);
        this.items.push({
          _id: customer._id,
          name: customer.name,
          email: customer.email,
          reseller: reseller ? reseller.name : "",
          zohoAccountId: customer.zohoAccountId,
          database: this.getDatabase(customer),
          loginUrl: this.getLoginUrl(customer),
          installedApps: "...",
          resellerCost: "...",
          customerTotal: "..."
        })
      }

      this.updatePricing(customers);
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  private async updatePricing(customers: Array<ZenduOne.Customer>) {
    try {
      const totals = await this._billingService.findCustomerTotal({
        customerIds: customers.map(c => c._id)
      });

      for (let item of this.items) {
        const total = totals.find(i => i.customerId == item._id);
        if (!total) {
          item.installedApps = "N/A";
          item.customerTotal = "N/A";
          item.resellerCost = "N/A";
          continue;
        }

        item.installedApps = total.installs;
        item.customerTotal = `$${total.customerTotal}`;
        item.resellerCost = `$${total.resellerCost}`;
      }
    }
    catch (err) {
      this._notify.error(err);
    }
  }

  public search() {
    clearTimeout(this._searchTimer);
    this._searchTimer = setTimeout(() => {
      this.paginator.firstPage();
      this.update();
    }, 1000);
  }

  private getDatabase(customer: ZenduOne.Customer) {
    if (!customer ||
      !customer.platforms ||
      !customer.platforms.length) {
      return "";
    }
    return customer.platforms[0].database;
  }

  public getLoginUrl(customer: ZenduOne.Customer) {
    let loginUrl = this._platformService.getLoginUrl(customer);
    return loginUrl;
  }


}
