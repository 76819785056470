import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { NotifierService } from 'src/app/services/notifier.service';
import { ResellerService } from 'src/app/services/reseller.service';
import { ZenduOne } from 'src/typings/app';

@Component({
  selector: 'app-resellers-list',
  templateUrl: './resellers-list.component.html',
  styleUrls: ['./resellers-list.component.scss']
})
export class ResellersListComponent implements OnInit {

  public isLoading: boolean;

  public dataSource = new MatTableDataSource();

  constructor(
    private _notify: NotifierService,
    private _resellerService: ResellerService
  ) {
  }

  ngOnInit() {
    this.init();
  }

  private async init() {
    try {
      this.isLoading = true;

      await this.update();
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  private async update() {
    try {
      this.isLoading = true;

      let items = await this._resellerService.find();
      this.dataSource.data = items;
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  public getStatus(reseller: ZenduOne.Reseller) {
    if(!reseller){
      return "";
    }
    if (reseller.paymentMode == "customer") {
      if (!reseller.stripeAccountId) {
        return "Not Connected"
      }
    }

    return "Active"
  }
}
