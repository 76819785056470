import { Component, Input, OnChanges } from '@angular/core';
import { ZenduOne } from 'src/typings/app';
import { Middleware } from 'src/typings/middleware';

@Component({
  selector: 'app-product-installation',
  templateUrl: './product-installation.component.html',
  styleUrls: ['./product-installation.component.scss']
})
export class ProductInstallationComponent implements OnChanges {

  @Input() product: ZenduOne.Product;

  constructor() { }

  ngOnChanges() {
    if (this.product) {
      this.update();
    }
  }

  private async update() {
    if (!this.product.installation) {
      this.product.installation = {
        users: { enabled: false },
        vehicles: { enabled: false },
        service: { enabled: false },
        customParams: []
      };
    }

    if (!this.product.installation.customParams) {
      this.product.installation.customParams = [];
    }

    if (!this.product.installation.service) {
      this.product.installation.service = { enabled: false }
    }

    if (!this.product.installation.vehicles) {
      this.product.installation.vehicles = { enabled: false }
    }

    if (!this.product.installation.users) {
      this.product.installation.users = { enabled: false }
    }

    if (!this.product.api) {
      this.product.api = { datasource: false }
    }
  }

  public removeParam(parameter: ZenduOne.ProductCustomParameter) {
    const idx = this.product.installation.customParams.indexOf(parameter);
    this.product.installation.customParams.splice(idx, 1);
  }

  public addParam(){
    this.product.installation.customParams.push({
      name: "",
      required: true,
      secure: false,
      label: ""
    });
  }
}
