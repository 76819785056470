import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ZenduOne } from 'src/typings/app';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { CancelConfirmComponent } from 'src/app/dialogs/cancel-confirm/cancel-confirm.component';
import { ProductPlatformEditComponent } from '../product-platform-edit/product-platform-edit.component';

@Component({
  selector: 'app-product-platforms',
  templateUrl: './product-platforms.component.html',
  styleUrls: ['./product-platforms.component.scss']
})
export class ProductPlatformsComponent implements OnInit {

  public isLoading: boolean;

  @Input() product: ZenduOne.Product;

  public dataSource = new MatTableDataSource();

  constructor(private _dialog: MatDialog) { }

  ngOnInit() {
  }

  private setProduct(product: ZenduOne.Product) {
    this.dataSource.data = product.platforms ? product.platforms : [];
  }

  public async createNew() {
    // open edit dialog
    let result = await this._dialog.open(ProductPlatformEditComponent,
      { data: null })
      .afterClosed().toPromise();

    // handle dialog result
    if (result) {
      // add a new item
      if (!this.product.platforms) {
        this.product.platforms = [];
      }
      this.product.platforms.push(result);
      this.dataSource.data = this.product.platforms;
    }
  }

  public async edit(platform: ZenduOne.ProductPlatform) {
    // open edit dialog
    await this._dialog.open(ProductPlatformEditComponent,
      { data: platform })
      .afterClosed().toPromise();
  }

  public async remove(platform: ZenduOne.ProductPlatform) {
    let confirm = await this._dialog.open(CancelConfirmComponent, {
      width: '350px',
      data: {
        title: "Confirm",
        text: `Are you sure would like to delete feature "${platform.id}" ?`
      }
    }).afterClosed().toPromise();
    if (!confirm) {
      return;
    }

    let idx = this.product.platforms.indexOf(platform)
    this.product.platforms.splice(idx, 1);
    this.dataSource.data = this.product.platforms;
  }

  ngOnChanges() {
    if (this.product) {
      this.setProduct(this.product);
    }
  }

}
