import { Component, Input, OnChanges } from '@angular/core';
import { ZenduOne } from 'src/typings/app';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { ProductFeatureEditComponent } from '../product-feature-edit/product-feature-edit.component';
import { CancelConfirmComponent } from 'src/app/dialogs/cancel-confirm/cancel-confirm.component';

@Component({
  selector: 'app-product-features',
  templateUrl: './product-features.component.html',
  styleUrls: ['./product-features.component.scss']
})
export class ProductFeaturesComponent implements OnChanges {

  public isLoading: boolean;

  public displayedColumns: string[] = [
    "name",
    "remove"
  ];

  @Input() isFeatures: boolean;

  @Input() product: ZenduOne.Product;

  public dataSource = new MatTableDataSource();

  constructor(private _dialog: MatDialog) { }

  ngOnChanges() {
    if (this.product) {
      this.setProduct(this.product);
    }
  }


  private setProduct(product: ZenduOne.Product) {
    if (this.isFeatures) {
      this.dataSource.data = product.content.features ? product.content.features : [];
    }
    else {
      this.dataSource.data = product.content.benefits ? product.content.benefits : [];
    }
  }

  public async createNew() {
    // open edit dialog
    let result = await this._dialog.open(ProductFeatureEditComponent,
      { data: null })
      .afterClosed().toPromise();

    // handle dialog result
    if (result) {
      if (this.isFeatures) {
        // add a new item
        if (!this.product.content.features) {
          this.product.content.features = [];
        }
        this.product.content.features.push(result);
        this.dataSource.data = this.product.content.features;
      }
      else {
        // add a new item
        if (!this.product.content.benefits) {
          this.product.content.benefits = [];
        }
        this.product.content.benefits.push(result);
        this.dataSource.data = this.product.content.benefits;
      }
    }
  }

  public async edit(feature) {
    // open edit dialog
    await this._dialog.open(ProductFeatureEditComponent,
      { data: feature })
      .afterClosed().toPromise();
  }

  public async remove(feature: ZenduOne.ProductFeature) {
    let confirm = await this._dialog.open(CancelConfirmComponent, {
      width: '350px',
      data: {
        title: "Confirm",
        text: `Are you sure would like to delete feature "${feature.name}"?`
      }
    }).afterClosed().toPromise();
    if (!confirm) {
      return;
    }

    if (this.isFeatures) {
      let idx = this.product.content.features.indexOf(feature)
      this.product.content.features.splice(idx, 1);
      this.dataSource.data = this.product.content.features;
    }
    else {
      let idx = this.product.content.benefits.indexOf(feature)
      this.product.content.benefits.splice(idx, 1);
      this.dataSource.data = this.product.content.benefits;
    }
  }


}
