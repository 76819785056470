import { Pipe, PipeTransform } from '@angular/core';
import { BillingType } from 'src/typings/billingType';

@Pipe({
    name: 'labelBillingType'
})
export class BillingLabelPipe implements PipeTransform {

    transform(value: string, args?: any): any {
        if (!value) {
            return;
        }
        switch (value) {
            case BillingType.fixed:
                return "Fixed";
            case BillingType.vehicles:
                return "By Vehicles";
            case BillingType.users:
                return "By Users";
            default:
                return value;
        }
    }

}
