import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { ZenduOne } from "src/typings/app";

@Injectable()
export class AuditService {
    constructor(private _httpService: HttpService) { }

    public async findOne(id: string): Promise<ZenduOne.AuditLog> {
        let items = await this._httpService.post("audit/find", { id: id });
        return items[0];
    }

    public async find(search: {
        dateFrom?: string | Date,
        dateTo?: string | Date,
        username?: string,
        customer?: string,
        offset?: number,
        limit?: number
    }):
        Promise<Array<ZenduOne.AuditLog>> {
        let items = await this._httpService.post("audit/find", search);
        return items;
    }

}