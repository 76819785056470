import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'src/app/services/notifier.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  public isLoading: boolean;

  public isCompleted: boolean;

  constructor(
    private _notifyService: NotifierService) {
  }

  ngOnInit() {
    this.init();
  }

  private async init() {
    try {
      this.isLoading = true;

    }
    catch (err) {
      this._notifyService.error(err, 10000000);
    }
    finally {
      this.isLoading = false;
    }
  }

  public async confirm() {
    try {
      this.isLoading = true;

      this.isCompleted = true;
    }
    catch (err) {
      this._notifyService.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }


}
