import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { ZenduOne } from "src/typings/app";

@Injectable()
export class ResellerService {
    constructor(private _httpService: HttpService) { }

    public async findOne(id: string): Promise<ZenduOne.Reseller> {
        let items = await this._httpService.post("resellers/find", { id: id });
        return items[0];
    }

    public async find(): Promise<Array<ZenduOne.Reseller>> {
        let items = await this._httpService.post("resellers/find", {});
        return items;
    }

    public async updateProductsAccess(productIds: string[]) {
        await this._httpService.post("resellers/products/access/update", { products: productIds });
    }

    public async findProductsAccess(): Promise<Array<{ productId: string, enabledForCustomer: boolean }>> {
        return await this._httpService.post("resellers/products/access/find", {});
    }

    public async update(reseller: ZenduOne.Reseller) {
        await this._httpService.post("resellers/update", reseller);
    }

    public async add(reseller: ZenduOne.Reseller): Promise<string> {
        let res = await this._httpService.post("resellers/add", reseller);
        return res.id;
    }

    /**
     * Connect stripe reseller account to own account
     */
    public async connect(options: { code: string }) {
        await this._httpService.post("resellers/connect", options);
    }

    public async findConfiguration(): Promise<ZenduOne.ResellerConfiguration> {
        return await this._httpService.post("resellers/configuration/find", {});
    }

    public async updateConfiguration(config: ZenduOne.ResellerConfiguration) {
        return await this._httpService.post("resellers/configuration/update", config);
    }

    public async completeRegistration(reseller: ZenduOne.Reseller) {
        await this._httpService.post("resellers/registration", reseller);
    }

    /**
     * find the configuration for demo database
     */
    public async findDemoConfig(): Promise<{ config: ZenduOne.DemoConfig, customerId: string }> {
        return await this._httpService.post("resellers/demo/find", {});
    }

    /**
     * update the demo configuration for demo database
     */
    public async updateDemoConfig(config: ZenduOne.DemoConfig) {
        return await this._httpService.post("resellers/demo/update", config);
    }
}